import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";

function Contact(){

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [phone, setphone] = useState('');
    const [subject, setsubject] = useState('');
    const [message, setmessage] = useState('');
    const [responseData, setResponseData] = useState('');

    const handleSubmit = () => {
        if (name.trim() === '') {
            alert("Enter Name");
        } else if (!validateEmail(email.trim())) {
            alert("Enter Valid Email Address");
        } else if (!validatePhoneNumber(phone.trim())) {
            alert("Enter Valid Phone No");
        } else if (subject.trim() === '') {
            alert("Enter Subject");
        } else if (message.trim() === '') {
            alert("Enter Message");
        } else {
        const url = 'https://www.nybaex.com/dnc/contact.php';
        let fData = new FormData();
        fData.append('name', name);
        fData.append('email', email);
        fData.append('phone', phone);
        fData.append('subject', subject);
        fData.append('message', message);
        axios.post(url, fData)
            .then(response => {
            setResponseData(response.data);
            })
            .catch(error => {
            console.error(error);
            });
        }
    }

    const validatePhoneNumber = (phoneNumber) => {
        const regex = /^\d{10}$/;
        return regex.test(phoneNumber);
    }

    const validateEmail = (email) => {
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    }

    return <>
        <section>
            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active nybaex_slider_img">
                        <div class="nybaex_topsection_background_other">
                            <div class="col-12 p-4 m-0">
                                <h4 class="m-0 nybaex_topsection_page_name_other">Contact Us &nbsp; <i class="bi bi-chat"></i></h4>
                                <hr />
                                <h2 class="nybaex_topsection_background_text_other">How can we help you with your query?</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="nybaex_dark_theme py-5">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <Link to="/career" style={{width: "100%"}}>
                            <div class="nybaex_section_contact_block text-center">
                                <div>
                                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iNTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgc3Ryb2tlLXdpZHRoPSI3IiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yNy4yNjEgNDYuODYybDIzLjY1LTIzLjYyMmM0LjIyMy00LjIxOSA0LjQ4Ny0xMS4wOC42LTE1LjYyNi0zLjg0Ny00LjUwMi0xMC41MDktNS4wMjQtMTQuOTgzLTEuMTc2bC02LjQ5IDUuNTgyaDAiIHN0cm9rZT0iI0ZDNzg0NCIvPjxwYXRoIGQ9Ik0zMi42NTggNDcuODYyTDcuNzg1IDI0LjE0MmMtNC40NDItNC4yMzYtNC43Mi0xMS4xMjYtLjYzMy0xNS42OSAzLjkxLTQuMzY4IDEwLjcwNS00LjgxMSAxNS4xNzYtLjk5LjA3Ny4wNjUuMTU0LjEzMi4yMjkuMmwxNC43MDQgMTMuMzEyaDAiIHN0cm9rZT0iI0ZGQUMxNSIvPjwvZz48L3N2Zz4=" alt="arrow icon" />
                                </div>
                                <h3 class="my-4 text-white">Working Together</h3>
                            </div>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="nybaex_section_contact_block text-center">
                            <div>
                                <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzEiIGhlaWdodD0iNDciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IHgxPSIwJSIgeTE9Ijg0LjEwMyUiIHgyPSIxMDAlIiB5Mj0iNDEuMTc2JSIgaWQ9ImEiPjxzdG9wIHN0b3AtY29sb3I9IiM3RTZFRTYiIG9mZnNldD0iMCUiLz48c3RvcCBzdG9wLWNvbG9yPSIjNjFDRENFIiBvZmZzZXQ9IjEwMCUiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cGF0aCBkPSJNMCAzOWwyMS4wMzEtMjEuMTAzIDE3LjUxNiA1LjM4MUw2MyAwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0IDQpIiBzdHJva2U9InVybCgjYSkiIHN0cm9rZS13aWR0aD0iOCIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=" alt="arrow icon" />
                            </div>
                            <h3 class="my-4 text-white">Send Feedback</h3>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="nybaex_section_contact_block text-center">
                            <div>
                                <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iNjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IHgxPSI1MCUiIHkxPSIwJSIgeDI9IjUwJSIgeTI9IjEwMCUiIGlkPSJhIj48c3RvcCBzdG9wLWNvbG9yPSIjQUM4RUVCIiBvZmZzZXQ9IjAlIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzY2MzZCNyIgb2Zmc2V0PSIxMDAlIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCA0KSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNNy4yNSA0LjEzN2wtMS4wNTIuODQ1QTE2LjU5NCAxNi41OTQgMCAwMDAgMTcuOTE2di41NTFjMCA1LjQ0NiAyLjE1OSAxMC42NyA2LjAwMyAxNC41MjdsNi4wODEgNi4xMDJoMFY0NGgxMy44MzJ2LTQuOTA0bDYuMDgtNi4xMDJBMjAuNTc4IDIwLjU3OCAwIDAwMzggMTguNDY3di0uNTUxYzAtNS4wMjgtMi4yOC05Ljc4NS02LjE5OC0xMi45MzRsLTEuMDUyLS44NDVhMTguNzU3IDE4Ljc1NyAwIDAwLTIzLjUgMHoiIHN0cm9rZT0idXJsKCNhKSIgc3Ryb2tlLXdpZHRoPSI3Ii8+PHBhdGggc3Ryb2tlPSIjNEYxRjk1IiBzdHJva2Utd2lkdGg9IjciIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIGQ9Ik0xMSA1NmgxNiIvPjxjaXJjbGUgZmlsbD0iI0ZEQUYyMCIgY3g9IjE5IiBjeT0iMjYiIHI9IjUiLz48L2c+PC9zdmc+" />
                            </div>
                            <h3 class="my-4 text-white">Register Compliant</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="nybaex_dark_theme pb-5">
            <div class="container-fluid nybaex_container_fluid_padding">
                <h3 style={{fontSize: "2rem"}}>Get in touch</h3>
                <h4 style={{fontWeight: "400", marginBottom: "4rem"}}>Thank you for your interest in our company. If you have any questions or comments, please don't hesitate to reach out to us.</h4>
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12 nybaex_contact_form mb-5">
                        <form>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                                <input
                                    type="text"
                                    name="name"
                                    className="nybaex_contact_input_box"
                                    placeholder="Name *"
                                    autoComplete="off"
                                    required
                                    id="name"
                                    value={name}
                                    onChange={(e) => setname(e.target.value)}
                                />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                                <input
                                    type="email"
                                    name="email"
                                    className="nybaex_contact_input_box"
                                    placeholder="Email Address *"
                                    autoComplete="off"
                                    required
                                    id="email"
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                                <input
                                    type="text"
                                    name="phone"
                                    className="nybaex_contact_input_box"
                                    placeholder="Phone No *"
                                    autoComplete="off"
                                    required
                                    id="phone"
                                    value={phone}
                                    onChange={(e) => setphone(e.target.value)}
                                />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                                <input
                                    type="text"
                                    name="subject"
                                    className="nybaex_contact_input_box"
                                    placeholder="Subject *"
                                    autoComplete="off"
                                    required
                                    id="subject"
                                    value={subject}
                                    onChange={(e) => setsubject(e.target.value)}
                                />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                                <textarea
                                    type="text"
                                    name="message"
                                    className="nybaex_contact_input_box"
                                    placeholder="How can we help you? *"
                                    autoComplete="off"
                                    required
                                    id="message"
                                    value={message}
                                    onChange={(e) => setmessage(e.target.value)}
                                ></textarea>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-4 nybaex_checkbox_section">
                                <input
                                    autoComplete="off"
                                    className="nybaex_contact_check_box"
                                    type="checkbox"
                                    required
                                    checked
                                />
                                <p className="form_checkbox_text">
                                    I give consent to Nybaex to process my personal data and contact me.
                                </p>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-4 nybaex_checkbox_section">
                                <p>
                                    For further details on how your personal data will be processed and how your consent can be managed,
                                    refer to the <span>Nybaex Privacy Notice</span>
                                </p>
                                </div>
                                <div className="col-12 mb-4">
                                <div dangerouslySetInnerHTML={{ __html: responseData }}></div>
                                </div>
                                <div className="col-12">
                                <input
                                    type="button"
                                    name="submit"
                                    id="submit"
                                    value="Send"
                                    onClick={handleSubmit}
                                    className="btn btn-secondary contact_btn"
                                />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-4 col-md-0 col-sm-0 mb-5 nybaex_contact_img_col">
                        <img src="./images/contact.jpg" class="nybaex_contact_img" width="100%" />
                    </div>
                </div>
            </div>
        </section>


        <section class="pb-3">
            <div class="container-fluid nybaex_container_fluid_padding">
                <h3 class="mb-5" style={{fontSize: "2rem"}}>Contact Details</h3>
                <p class="mb-5" style={{textAlign: "justify"}}>You can reach us by phone at <b>+91 8986717542</b>. Our customer support team is available Monday through Friday, 9:00 AM to 5:00 PM IST.
                <br />
                For general inquiries, please email us at <b>support@nybaex.com</b>. We will do our best to respond to your message within 24 hours.
                <br /><br />
                <h3 class="mb-3 mt-0">Mailing Address</h3>
                If you prefer to send us a letter, our mailing address is:
                <br /><br />
                Nybaex Private Limited,
                H. No. 17/C, Near Shastri Chowk, Madhukam - 834001, (Ranchi) Jharkhand
                <br /><br />
                <h3 class="mb-3 mt-0">Social Media</h3>
                Follow us on social media for the latest news and updates from our company:<br />
                <div class="my-3">
                    <a href="https://api.whatsapp.com/send?phone=+918986717542&text=Hi" target="_blank" class="nybaex_bottom_footer_smlg_a"><i class="bi bi-whatsapp"></i></a>
                    <a href="https://facebook.com/nybaex" target="_blank" class="nybaex_bottom_footer_smlg_a"><i class="bi bi-facebook"></i></a>
                    <a href="https://youtube.com/@nybaex" target="_blank" class="nybaex_bottom_footer_smlg_a"><i class="bi bi-youtube"></i></a>
                    <a href="https://twitter.com/nybaex" target="_blank" class="nybaex_bottom_footer_smlg_a"><i class="bi bi-twitter"></i></a>
                    <a href="https://linkedin.com/nybaex" target="_blank" class="nybaex_bottom_footer_smlg_a_last"><i class="bi bi-linkedin"></i></a>
                </div>
                Thank you for choosing Nybaex Private Limited. We look forward to hearing from you soon.
                <br /><br />
                At Nybaex Private Limited, we pride ourselves on delivering innovative IT/Tech solutions to help businesses thrive in today's digital world. We understand that choosing the right technology partner can be a daunting task, and we're here to make the process as smooth as possible. If you have any questions, comments, or concerns, we encourage you to reach out to us. Our team of knowledgeable and experienced professionals is ready to assist you and provide you with the information you need.
                </p>
            </div>
        </section>
    </>
}

export default Contact;
import { useEffect } from "react";

function Career(){

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <section>
            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active nybaex_slider_img">
                        <div class="nybaex_topsection_background_other">
                            <div class="col-12 p-4 m-0">
                                <h4 class="m-0 nybaex_topsection_page_name_other">Career &nbsp; <i class="bi bi-envelope"></i></h4>
                                <hr />
                                <h2 class="nybaex_topsection_background_text_other">Join Us and shape your future at Nybaex</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="nybaex_mention_details_about py-5 my-3">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 nybaex_mention_details_about_col">
                        <img class="nybaex_career_section_imgage" src="./images/career/1.png" alt="" loading="lazy" />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 nybaex_mention_details_about_col">
                        <div class="pg-content">
                            <h2 class="pg-heading mb-4">Welcome to our world: Our people, our culture & our passions.</h2>
                            <p>What’s destination next? Is it better technology? A better you? Or a better community of makers? Wherever you desire to go, we give you the tools, the techniques, and the teams to navigate the journey.</p>
                            <div class="pg-button-container mt-5">
                                <a href="#" class="btn primary-btn px-5 nybaex_button_bg">Contact Us</a>
                                <a href="#" class="btn primary-btn px-5 mt-3 nybaex_button_outline">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 


        <section class="nybaex_mention_details_about pb-5 mt-5">
            <div class="container-fluid nybaex_container_fluid_padding">
                <h2>We are Hiring</h2>
                <div class="p-4 mt-5 nybaex_we_are_hiring_div">
                    <div class="row">
                        <div class="col-lg-8 col-md-6 col-sm-12 nybaex_we_are_hiring_col">
                            <img src="https://cdn-icons-png.flaticon.com/512/3459/3459528.png" width="70" height="70" />
                            <div class="mx-3 nybaex_we_are_hiring_col_text">
                                <h4 class="m-0">React Developer (3+ Years Experience)</h4>
                                <h4 class="text-gray m-0">28/07/2023</h4>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 nybaex_we_are_hiring_col_button_div">
                            <a href="mailto:hr@nybaex.com" class="btn primary-btn px-5"><i class="bi bi-envelope"></i></a>
                        </div>
                    </div>
                </div>
                <div class="p-4 mt-3 nybaex_we_are_hiring_div">
                    <div class="row">
                        <div class="col-lg-8 col-md-6 col-sm-12 nybaex_we_are_hiring_col">
                            <img src="https://cdn-icons-png.flaticon.com/512/3459/3459528.png" width="70" height="70" />
                            <div class="mx-3 nybaex_we_are_hiring_col_text">
                                <h4 class="m-0">Node Developer (2+ Years Experience)</h4>
                                <h4 class="text-gray m-0">25/07/2023</h4>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 nybaex_we_are_hiring_col_button_div">
                            <a href="mailto:hr@nybaex.com" class="btn primary-btn px-5"><i class="bi bi-envelope"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section> 


        <section class="pb-3">
            <div class="container-fluid nybaex_container_fluid_padding">
                <h3 class="mb-5" style={{fontSize: "2rem"}}>Join Our Team of IT Innovators</h3>
                <p class="mb-5" style={{textAlign: "justify"}}>At Nybaex Private Limited, we are passionate about technology and innovation. Our team consists of talented, driven individuals who are dedicated to creating cutting-edge solutions that meet the evolving needs of our clients. If you share our passion for technology and have a desire to make a real impact in the industry, we want to hear from you!
                <br /><br />
                <h3 class="mb-3 mt-0">Career Opportunities</h3>
                We are always looking for talented individuals to join our team. We offer a variety of career opportunities across different departments. We offer competitive salaries, comprehensive benefits packages, and opportunities for professional growth and development. Our supportive and collaborative work environment fosters an atmosphere of innovation and creativity, making it the perfect place for you to advance your career in technology.
                <br /><br />
                <h3 class="mb-3 mt-0">Our Culture</h3>
                At Nybaex Private Limited, we believe that our team is our greatest asset. We have created a dynamic and inclusive work environment where our employees can thrive both personally and professionally. Our commitment to diversity and equality means that every member of our team feels valued and supported. We are a fast-paced, results-driven company, and we believe in the power of hard work and collaboration to achieve great things. We are committed to helping our employees achieve their career goals and provide them with the resources and support they need to succeed. <br /><br />
                If you are looking for a dynamic, innovative and supportive environment in which to grow your career in technology, Nybaex Private Limited is the place for you!
                <br /><br />
                <h3 class="mb-3 mt-0">Apply Now</h3>
                Ready to join our team of IT innovators? Submit your resume and cover letter to <b>hr@nybaex.com</b>. We look forward to hearing from you!"
                <br /><br />
                If you're passionate about technology and want to work in a dynamic and innovative environment, we encourage you to explore our career opportunities and submit your application today. At Nybaex Private Limited, we believe that our employees are the key to our success. We are constantly looking for talented and passionate individuals to join our team and help us deliver innovative IT/Tech solutions to our clients. As an equal opportunity employer, we welcome applicants from all backgrounds and strive to create a diverse and inclusive workplace where everyone feels valued and respected.
                </p>
            </div>
        </section>
    </>
}

export default Career;
import { Link } from "react-router-dom";
import { useEffect } from "react";

function Home(){

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <section>
            <div id="carouselExampleIndicators">
                <div class="carousel-inner">
                    <div class="carousel-item active nybaex_slider_img">
                        <div class="nybaex_slider_background_img_1">
                            <div class="container nybaex_slider_background_img_row">
                                <div class="row nybaex_slider_background_img_row">
                                    <div class="col-lg-8 col-md-6 col-sm-0"></div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 nybaex_slider_background_img_text" style={{paddingLeft: "3rem"}}>
                                        <h4 class="m-0">Nybaex</h4>
                                        <h1 class="m-0 nybaex_home_slider_h1" style={{lineHeight: "0.8"}}>Innovating Technology</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <div class="scroll-down container-fluid nybaex_container_fluid_padding nybaex_website_get_started_button">
            <a href="#get_started">
                <span class="blink blink-infinite blink-1"></span>
                <span class="blink blink-infinite blink-2"></span>
                <span class="blink blink-infinite blink-3"></span>
                <span class="blink blink-infinite blink-4"></span>
                <span class="blink blink-infinite blink-5"></span>
            </a>
        </div>

        <section class="pt-5" id="get_started"></section>

        <section class="nybaex_app_details_home nybaex_dark_theme">
            <div class="container-fluid nybaex_app_details_home_container nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 nybaex_app_details_home_col">
                        <div class="pg-content">
                            <h2 class="pg-heading mb-5">Welcome to Nybaex, the leading provider of innovative technology solutions</h2>
                            <p>Our mission is to empower companies to reach their full potential through the use of cutting-edge technology. We offer a wide range of services including Website Designing & Development, Digital Marketing, App Development, Software Development, Graphic Designing and many other latest Technology Services. <br /><br />
                            With more than 12 years of experience and a team of highly skilled Professional. We guarrantee to serve all customers, in any type of project, big or small.</p>
                            <div class="pg-button-container mt-5">
                                <Link to="/contact" class="btn primary-btn px-5 mb-2 nybaex_button_bg">Contact Us</Link>
                                <Link to="/about" class="btn primary-btn px-5 mb-2 nybaex_button_outline">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 nybaex_app_details_home_col">
                        <img class="nybaex_app_details_home_col_img" src="./images/home/5.png" alt="" />
                    </div>
                </div>
            </div>
        </section>


        <section class="nybaex_who_we_are_home nybaex_dark_theme mt-5">
                <div class="row py-4">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="w-100">
                            <img class="nybaex_who_we_are_home_img img-fluid w-100" src="images/meeting.jpg" alt="" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 nybaex_who_we_are_home_col">
                        <div class="nybaex_who_we_are_home_details p-5">
                            <h4 class="text-black">WHO WE ARE</h4>
                            <h2 class="text-black">We build futures through innovation</h2>
                            <p  class="text-black mt-4"><b>Nybaex Private Limited</b> is an IT company, consulting and business solution's organization that has been partnering with many of the India’s largest businesses in their transformation journeys. Serving over 640+ clients throughout India and Foreign Countries.</p>
                        </div>
                    </div>
                </div>
        </section>


        <section class="nybaex_dark_theme py-4">
            <div class="container-fluid nybaex_container_fluid_padding">
                <h2 class="nybaex_section_headline_info_home mb-5">Nybaex <br /> Innovating Technology.</h2>
                <p class="col-lg-7 mb-4 nybaex_info_paragraph_home"><span style={{color: '#fff'}}>Nybaex Private Limited</span> is an innovative Information Technology company which is committed to stay ahead of the curve in terms of technological advancements, and is always on the lookout for new and innovative ways to help businesses succeed in the digital age. We have a team of highly skilled & dedicated developers.</p>
                <p class="nybaex_info_link_home"><Link to="/about">Explore more about Nybaex &nbsp;...</Link></p>
            </div>
        </section>


        <section class="nybaex_get_best_quote_home nybaex_dark_theme pt-5">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="nybaex_get_best_quote_home_h2">
                    <h2 class="nybaex_get_best_quote_home_h2_text">Make a call & get best quote</h2>
                    <h4 class="mt-3 nybaex_get_best_quote_home_p">If you’re looking for innovation, results and a team of web, programming & multimedia experts <br /> for your business, feel free to contact us at <b>support@nybaex.com</b></h4>
                </div>
            </div>
        </section>


        <section class="nybaex_digital_marketing_home nybaex_dark_theme pt-5 pb-4 mt-4">
            <div class="container-fluid nybaex_digital_marketing_home_container nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 nybaex_digital_marketing_home_col">
                        <img class="nybaex_long_term_relationships_home_col_img" src="./images/home/relationship.png" alt="" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 nybaex_digital_marketing_home_col">
                        <div class="pg-content">
                            <h2 class="pg-heading mb-4">We believe in building long-term relationships</h2>
                            <p>We understand your unique needs and goals, and tailor our solutions to meet those specific requirements. Our dedicated support team is available 24/7 to answer any questions and provide ongoing maintenance and support. Discover the power of technology and how it can transform your business. 
                            <br /><br />    
                            Contact us today to schedule a free consultation and see how we can help you reach new heights. Join the ranks of our satisfied clients and experience the benefits of working with us.</p>
                            <div class="pg-button-container mt-5">
                                <Link to="/contact" class="btn primary-btn px-5 mb-2 nybaex_button_bg">Contact Us</Link>
                                <Link to="/about" class="btn primary-btn px-5 mb-2 nybaex_button_outline">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="nybaex_why_nybaex_home nybaex_dark_theme py-5 nybaex_padding_why_section_home">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-5">
                        <div class="nybaex_why_nybaex_home_block">
                            <div class="py-5 nybaex_why_nybaex_home_block_img_1">
                                <img src="./images/home/11.png" width="170" alt="arrow icon" />
                            </div>
                            <div class="nybaex_why_nybaex_home_block_text p-3">
                                <h3 class="my-4">Grow your business faster with the help of tech</h3>
                                <p>Now a days technology has a power to shine your business world wide & connect people throughout the globe.</p>
                            </div>
                            <div class="nybaex_why_nybaex_home_bottom">
                                <div class="nybaex_why_nybaex_home_bottom_line"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-5">
                        <div class="nybaex_why_nybaex_home_block">
                            <div class="py-5 nybaex_why_nybaex_home_block_img_2">
                                <img src="./images/home/12.png" width="160" alt="arrow icon" />
                            </div>
                            <div class="nybaex_why_nybaex_home_block_text p-3">
                                <h3 class="my-4">We serve services at very competitive Cost</h3>
                                <p>All of our services is being offered at very Competitive cost, we serve clients without hurting their pockets.</p>
                            </div>
                            <div class="nybaex_why_nybaex_home_bottom">
                                <div class="nybaex_why_nybaex_home_bottom_line"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-5">
                        <div class="nybaex_why_nybaex_home_block">
                            <div class="py-5 nybaex_why_nybaex_home_block_img_3">
                                <img src="./images/home/13.png" width="190" alt="arrow icon" />
                            </div>
                            <div class="nybaex_why_nybaex_home_block_text p-3">
                                <h3 class="my-4">Get 24*7 Service Support from Nybaex Team</h3>
                                <p>Our team is at active mode for any of your queries. You will get 24*7 Service Support from Nybaex Team.</p>
                            </div>
                            <div class="nybaex_why_nybaex_home_bottom">
                                <div class="nybaex_why_nybaex_home_bottom_line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="section mb-5">
            <div class="container-fluid nybaex_container_fluid_padding nybaex_get_in_touch_home_container">
                <div class="nybaex_explore_your_project_home">
                    <div class="row">
                        <div class="col-lg-6 col-md-8 col-sm-12 nybaex_explore_your_project_home_row py-4">
                            <div class="nybaex_explore_your_project_home_block py-5 m-4" style={{background: "#ffffff"}}>
                                <span class="h6 text-color" style={{color: "#000000"}}>
                                    Get in touch &nbsp; <i class="bi bi-headset"></i>
                                </span>
                                <h2 class="mt-2 mb-4" style={{color: "#000000"}}>Explore your project with our team !</h2>
                                <p class="lead mb-4" style={{color: "#000000"}}>If you have any project on mind? For enquiry :</p>
                                <h3 style={{color: "#000000"}}><i class="ti-mobile mr-3 text-color" style={{color: "#000000"}}></i>+91 8986717542</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="nybaex_our_services_home nybaex_dark_theme py-5">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="nybaex_our_services_home_block">
                            <div>
                                <img src="./images/icons/1.png" width="70" alt="Website Development" />
                            </div>
                            <h3 class="my-4 text-white">Website Designing & Development</h3>
                            <p class="text-white">Website Designing & Developement services encompasses of designing and development of websites.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="nybaex_our_services_home_block">
                            <div>
                                <img src="./images/icons/2.png" width="70" alt="App Development" />
                            </div>
                            <h3 class="my-4 text-white">Android & IOS App Development</h3>
                            <p class="text-white">Android & IOS application is a type of application software designed to run on a mobile / smartphone device.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="nybaex_our_services_home_block">
                            <div>
                                <img src="./images/icons/3.png" width="70" alt="Digital Marketing" />
                            </div>
                            <h3 class="my-4 text-white">Digital Marketing & Social Media Marketing</h3>
                            <p class="text-white">Digital marketing refers to the use of digital channels to market products & services in order to reach consumers.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="nybaex_our_services_home_block">
                            <div>
                                <img src="./images/icons/4.png" width="70" alt="Maintenance Services" />
                            </div>
                            <h3 class="my-4 text-white">Website & Application Maintenance</h3>
                            <p class="text-white">Website & App maintenance services are regular monitoring of the Website & App for fixing issues & inaccuracy.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="nybaex_our_services_home_block">
                            <div>
                                <img src="./images/icons/5.png" width="70" alt="Graphic Designing" />
                            </div>
                            <h3 class="my-4 text-white">Graphic Designing & Animations</h3>
                            <p class="text-white">Graphic Design is the process of creating visual content to communicate information / messages to the masses.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="nybaex_our_services_home_block">
                            <div>
                                <img src="./images/icons/6.png" width="70" alt="Cyber Security" />
                            </div>
                            <h3 class="my-4 text-white">Cyber Security Services & Management</h3>
                            <p class="text-white">Cyber Security is the protection of computer systems and networks from attack by malicious hackers.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="nybaex_testimonials_home nybaex_dark_theme pt-4">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 mb-5">
                        <span class="h6 text-color">Testimonials</span>
                        <h2 class="pg-heading mb-3">What our clients say <br /> about us</h2>
                        <p class="mb-3">Our mission is to help customers achieve their business objectives by providing innovative, best-in-class consulting, IT solutions.</p>
                        <p>Clients who are satisfied with our services have had a positive experience with the services we provide. Satisfied clients may leave reviews or testimonials.</p>
                        <Link to="contact" class="btn primary-btn px-5 mt-4">Contact Us</Link>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="nybaex_testimonials_home_block">
                            <div class="nybaex_testimonials_home_quote">
                                <i class="nybaex_testimonials_home_quote_i bi bi-quote"></i>
                            </div>
                            <div class="nybaex_testimonials_home_block_content">
                                <div id="nybaexOurClients" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-indicators nybaex_our_clients_carousel_indicators_home">
                                    <button type="button" data-bs-target="#nybaexOurClients" data-bs-slide-to="0" class="nybaex_team_members_carousel_indicators_button_home active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#nybaexOurClients" data-bs-slide-to="1" aria-label="Slide 2" class="nybaex_team_members_carousel_indicators_button_home"></button>
                                    <button type="button" data-bs-target="#nybaexOurClients" data-bs-slide-to="2" aria-label="Slide 3" class="nybaex_team_members_carousel_indicators_button_home"></button>
                                    <button type="button" data-bs-target="#nybaexOurClients" data-bs-slide-to="3" aria-label="Slide 4" class="nybaex_team_members_carousel_indicators_button_home"></button>
                                    <button type="button" data-bs-target="#nybaexOurClients" data-bs-slide-to="4" aria-label="Slide 5" class="nybaex_team_members_carousel_indicators_button_home"></button>
                                </div>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div class="nybaex_testimonials_home_block_content_single">
                                                <div>
                                                    <p class="nybaex_testimonials_home_block_content_single_text">“Nybaex exceeded all of our expectations with their website design & development services. From the initial consultation to the final launch, the team was communicative, collaborative, and address our concerns.”</p>
                                                </div>
                                                <div class="testimonial-author d-sm-flex justify-content-between">
                                                    <div class="author-info d-flex align-items-center">
                                                        <div class="author-image">
                                                            <img src="./images/clients/sadguna.png" width="80" alt="author" />
                                                        </div>
                                                        <div class="nybaex_testimonials_home_block_content_single_client">
                                                            <h5 class="nybaex_testimonials_home_block_content_single_name">Suman Dubey</h5>
                                                            <span class="nybaex_testimonials_home_block_content_single_title">Managing Director <br /> Sadguna Masale</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="carousel-item">
                                            <div class="nybaex_testimonials_home_block_content_single">
                                                <div>
                                                    <p class="nybaex_testimonials_home_block_content_single_text">“Nybaex provided exceptional website designing & development services that helped bring our vision to life. The team was responsive, creative, and delivered a top-quality website that has exceeded our expectations.”</p>
                                                </div>
                                                <div class="testimonial-author d-sm-flex justify-content-between">
                                                    <div class="author-info d-flex align-items-center">
                                                        <div class="author-image">
                                                            <img src="./images/clients/royalsion.png" width="80" alt="author" />
                                                        </div>
                                                        <div class="nybaex_testimonials_home_block_content_single_client">
                                                            <h5 class="nybaex_testimonials_home_block_content_single_name">Sukumar Bose</h5>
                                                            <span class="nybaex_testimonials_home_block_content_single_title">Managing Director <br /> Hotel Royalsion</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="carousel-item">
                                            <div class="nybaex_testimonials_home_block_content_single">
                                                <div>
                                                    <p class="nybaex_testimonials_home_block_content_single_text">“Working with Nybaex was a pleasure, their attention to detail, knowledge of industry trends, and exceptional design skills resulted in a website that perfectly captured our brand identity and surpassed our goals.”</p>
                                                </div>
                                                <div class="testimonial-author d-sm-flex justify-content-between">
                                                    <div class="author-info d-flex align-items-center">
                                                        <div class="author-image">
                                                            <img src="./images/clients/albedo.png" width="80" alt="author" />
                                                        </div>
                                                        <div class="nybaex_testimonials_home_block_content_single_client">
                                                            <h5 class="nybaex_testimonials_home_block_content_single_name">Saroj Pathak</h5>
                                                            <span class="nybaex_testimonials_home_block_content_single_title">Chairmain <br /> Albedo Education</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="carousel-item">
                                            <div class="nybaex_testimonials_home_block_content_single">
                                                <div>
                                                    <p class="nybaex_testimonials_home_block_content_single_text">“We are extremely satisfied with the website design services provided by Nybaex Private Limited. They were efficient, reliable, and delivered a website that was not only visually stunning but also user-friendly.”</p>
                                                </div>
                                                <div class="testimonial-author d-sm-flex justify-content-between">
                                                    <div class="author-info d-flex align-items-center">
                                                        <div class="author-image">
                                                            <img src="./images/clients/raftaar.png" width="70" alt="author" />
                                                        </div>
                                                        <div class="nybaex_testimonials_home_block_content_single_client">
                                                            <h5 class="nybaex_testimonials_home_block_content_single_name">Gaurav Kumar</h5>
                                                            <span class="nybaex_testimonials_home_block_content_single_title">Managing Director <br /> Raftaar Media</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="carousel-item">
                                            <div class="nybaex_testimonials_home_block_content_single">
                                                <div>
                                                    <p class="nybaex_testimonials_home_block_content_single_text">“Working with Nybaex Private Limited was a fantastic experience. They took the time to understand our unique business needs and designed a website that perfectly captures our brand and messaging.”</p>
                                                </div>
                                                <div class="testimonial-author d-sm-flex justify-content-between">
                                                    <div class="author-info d-flex align-items-center">
                                                        <div class="author-image">
                                                            <img src="./images/clients/asha.png" width="70" alt="author" />
                                                        </div>
                                                        <div class="nybaex_testimonials_home_block_content_single_client">
                                                            <h5 class="nybaex_testimonials_home_block_content_single_name">Rohit Tiwari</h5>
                                                            <span class="nybaex_testimonials_home_block_content_single_title">Proprietor <br /> Asha Industries</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div> 
            </div> 
        </section>


        <section class="nybaex_dark_theme mb-4">
                <div class="row py-4">
                    <div class="col-lg-6 col-md-6 col-sm-12 nybaex_explore_services_home_col">
                        <div class="nybaex_explore_services_home_details p-5">
                            <h4 class="text-black">A TOUR OF</h4>
                            <h2 class="text-black">Offering Services by <br /> Nybaex</h2>
                            <p  class="text-black mt-4">At Nybaex, we are dedicated to delivering innovative and reliable IT solutions that meet the unique needs of each of our clients.</p>
                            <Link to="/services" class="btn primary-btn px-5 mt-4 mb-2 nybaex_button">Explore Now</Link>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="w-100">
                            <img class="nybaex_explore_services_home_img img-fluid w-100" src="images/services.jpg" alt="" />
                        </div>
                    </div>
                </div>
        </section>


        <section class="pb-5 mb-3 nybaex_main_services_home_section pt-5">
            <div class="row nybaex_main_service_home_scrollable_row pb-4 pt-2">
                <div class="col-lg-3 col-md-4 col-sm-6 nybaex_main_services_col_block_left">
                    <div class="py-5 px-4 nybaex_main_services_home_div_block">
                        <img src="./images/icons/7.png" width="70" alt="Certified Professional" />
                        <h3 class="my-4 text-white">Experienced & Certified Professional</h3>
                        <p class="text-white">Experienced & certified professionals bring a wealth of knowledge and skills that can drive innovation & success in the industry.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="py-5 px-4 nybaex_main_services_home_div_block">
                        <img src="./images/icons/8.png" width="70" alt="Faster Development" />
                        <h3 class="my-4 text-white">Faster Development & Deliveries</h3>
                        <p class="text-white">We use cutting-edge project management tools & agile development methodologies to accelerate project delivery.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="py-5 px-4 nybaex_main_services_home_div_block">
                        <img src="./images/icons/15.png" width="70" alt="Client Success" />
                        <h3 class="my-4 text-white">Focused on client’s success</h3>
                        <p class="text-white">We are committed to delivering customized solutions that align with our client's goals & objectives, ensuring their success.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="py-5 px-4 nybaex_main_services_home_div_block">
                        <img src="./images/icons/10.png" width="70" alt="Information Security" />
                        <h3 class="my-4 text-white">Security of client’s information</h3>
                        <p class="text-white">Our security protocols & measures to safeguard client information, ensuring maximum protection and confidentiality.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 nybaex_main_services_col_block_right">
                    <div class="py-5 px-4 nybaex_main_services_home_div_block">
                        <img src="./images/icons/9.png" width="70" alt="Transformation" />
                        <h3 class="my-4 text-white">Services at Competitive Cost</h3>
                        <p class="text-white">We offer Services at Competitive Cost, enabling businesses of all sizes to access cutting-edge technology services.</p>
                    </div>
                </div>
            </div>
        </section>
    </>
  }
  
  export default Home;
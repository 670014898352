import { useEffect } from "react";

function Error(){

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <section class="d-flex align-items-center py-5 nybaex_dark_theme">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <h1 class="display-1">Error 404</h1>
                        <p class="mb-md-5 mb-4 mx-md-0 mx-auto pb-2 lead">The page you are looking for was moved, removed or might never existed.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 not_found_animation">
                        <lottie-player src="assets/json/2.json" background="transparent" speed="1" loop autoplay style={{width: "400px"}}></lottie-player>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Error;
import { Link } from "react-router-dom";
import { useEffect } from "react";

function About(){

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <section>
            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active nybaex_slider_img">
                        <div class="nybaex_topsection_background_other">
                            <div class="col-12 p-4 m-0">
                                <h4 class="m-0 nybaex_topsection_page_name_other">About Us &nbsp; <i class="bi bi-award"></i></h4>
                                <hr />
                                <h2 class="nybaex_topsection_background_text_other">Empower your business with technology</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="py-5">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 nybaex_quote_section_about">
                        <h1 class="heading text-white pt-2 pb-4 nybaex_quote_heading_about">Empowering Your Vision with Our Innovative Technology</h1>
                        <p>Nybaex Private Limited a leading IT solutions provider dedicated to helping businesses succeed in the digital age. With a team of experienced and knowledgeable professionals, we provide cutting-edge technology solutions to help companies stay ahead and competitive in today's fast-paced market.</p>
                        <p>We are committed to quality, excellence, and customer satisfaction, and we work closely with our clients to ensure that we're delivering solutions that exceed their expectations.</p>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 nybaex_quote_section_about_col">
                        <img class="nybaex_about_nybaex_about_image" src="./images/about/0.png" width="600" />
                    </div>
                </div> 
            </div> 
        </section>


        <section class="nybaex_mention_details_about my-5 pb-2">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 nybaex_mention_details_about_col">
                        <img class="nybaex_why_choose_us_about_image mb-5" src="./images/about/1.png" alt="" loading="lazy" />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 nybaex_mention_details_about_col">
                        <div class="pg-content">
                            <h2 class="pg-heading mb-4">Why choose us?</h2>
                            <p>We continuously strive to adopt the newest technology trends & popular platforms for implementing successfully on business applications. Our tech Knowledge help brands to enhance their business operations & services towards their customers. We have a team of highly skilled & dedicated developers who have years of experience in latest technology & having the capabilities to accomplish projects within a specified deadline.</p>
                            <p>Nybaex's vision is to provide the optimum quality of services to our clients in every phase of their relationship. Understanding the client's requirements & consistently exceeding their expectations is what makes Nybaex superior to others.</p>
                            <div class="pg-button-container mt-5">
                                <Link to="/contact" class="btn primary-btn px-5 nybaex_button_bg">Contact Us</Link>
                                <Link to="/services" class="btn primary-btn px-5 mt-3 nybaex_button_outline">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="section py-5 m-2 mb-5 nybaex_everything_possible_about">
            <div class="container-fluid nybaex_container_fluid_padding">
                <h2 class="heading nybaex_everything_possible_heading_about">Everything is possible with today's Technology</h2>
                <h2 class="my-5 nybaex_everything_possible_sub_heading_about">Take your business online with best performance website & apps by a world-class platform which helps to grow your business faster around the globe. Our created website & apps are Secure, SEO Friendly and great UI experience for your customers.</h2>
            </div>
        </section>


        <section class="nybaex_hear_from_our_team_home nybaex_dark_theme py-5 mt-4 mb-5">
            <div id="nybaexTeamMembers" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators container-fluid nybaex_container_fluid_padding nybaex_team_members_carousel_indicators_home nybaex_hear_from_our_team_carousel_indicators">
                    <button type="button" data-bs-target="#nybaexTeamMembers" data-bs-slide-to="0" class="nybaex_team_members_carousel_indicators_button_home active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#nybaexTeamMembers" data-bs-slide-to="1" aria-label="Slide 2" class="nybaex_team_members_carousel_indicators_button_home"></button>
                    <button type="button" data-bs-target="#nybaexTeamMembers" data-bs-slide-to="2" aria-label="Slide 3" class="nybaex_team_members_carousel_indicators_button_home"></button>
                    <button type="button" data-bs-target="#nybaexTeamMembers" data-bs-slide-to="3" aria-label="Slide 4" class="nybaex_team_members_carousel_indicators_button_home"></button>
                    <button type="button" data-bs-target="#nybaexTeamMembers" data-bs-slide-to="4" aria-label="Slide 5" class="nybaex_team_members_carousel_indicators_button_home"></button>
                    <button type="button" data-bs-target="#nybaexTeamMembers" data-bs-slide-to="5" aria-label="Slide 6" class="nybaex_team_members_carousel_indicators_button_home"></button>
                    <button type="button" data-bs-target="#nybaexTeamMembers" data-bs-slide-to="6" aria-label="Slide 7" class="nybaex_team_members_carousel_indicators_button_home"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="container-fluid nybaex_container_fluid_padding">
                            <div class="row">
                                <div class="col-lg-3 col-md-2 col-sm-12 nybaex_hear_from_our_team_quote_icon">
                                    <img src="images/quote.svg" class="nybaex_hear_from_our_team_quote_image" width="200" />
                                </div>
                                <div class="col-lg-9 col-md-10 col-sm-12 nybaex_team_col_div">
                                    <h2>Hear from our team</h2>
                                    <div>
                                        <div class="nybaex_hear_from_our_team_home_text">
                                            <h4 class="mt-3 mb-3">Chairman & Managing Director</h4>
                                            <div class="pb-3 nybaex_team_content_div">
                                                <p>Mr. Law Kumar is the Chairman & Managing Director at Nybaex. He comes with 08 years of experience in enterprise website development, e-commerce platforms and apps. Prior to Nybaex he was working as the  Web Designer & Developer (with numerous companies) and building the best-in-class Websites & Apps with world-class technology.</p>
                                                <p>As the chairman of the company, Law Kumar is responsible for overseeing the overall direction of Nybaex and ensuring that the team is working effectively.</p>
                                            </div>
                                            <h4 class="mt-4">Mr. Law Kumar</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="container-fluid nybaex_container_fluid_padding">
                            <div class="row">
                                <div class="col-lg-3 col-md-2 col-sm-12 nybaex_hear_from_our_team_quote_icon">
                                    <img src="images/quote.svg" width="200" class="nybaex_hear_from_our_team_quote_image" />
                                </div>
                                <div class="col-lg-9 col-md-10 col-sm-12 nybaex_team_col_div">
                                    <h2>Hear from our team</h2>
                                    <div>
                                        <div class="nybaex_hear_from_our_team_home_text">
                                            <h4 class="mt-3 mb-3">President & Director</h4>
                                            <div class="pb-3 nybaex_team_content_div">
                                                <p>Mr. Bisheshwar Pandit is the President and Director of Nybaex Private Limited, one of the leading IT solutions & consulting company of Jharkhand. As the Company's President, &nbsp; Mr. Pandit ensures performance & scalability of the Company's operating model to enable sustainable growth. With his extensive knowledge and expertise.
                                                </p>
                                                <p>Mr. Bisheshwar Pandit is committed to innovation, delivery excellence, and improving capabilities, processes, and talent.</p>
                                            </div>
                                            <h4 class="mt-4">Mr. Bisheshwar Pandit</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="container-fluid nybaex_container_fluid_padding">
                            <div class="row">
                                <div class="col-lg-3 col-md-2 col-sm-12 nybaex_hear_from_our_team_quote_icon">
                                    <img src="images/quote.svg" width="200" class="nybaex_hear_from_our_team_quote_image" />
                                </div>
                                <div class="col-lg-9 col-md-10 col-sm-12 nybaex_team_col_div">
                                    <h2>Hear from our team</h2>
                                    <div>
                                        <div class="nybaex_hear_from_our_team_home_text">
                                            <h4 class="mt-3 mb-3">Honorary Director</h4>
                                            <div class="pb-3 nybaex_team_content_div">
                                                <p>Mr. Krishna Kant Dubey, an IITian with a Post Graduate Executive MBA from IIM. Mr. Dubey is a techno-commercial professional with more than 30 years of industrial experience in top multinationals viz Vedanta Group (Sesa Goa Ltd & Madras Aluminum Company Ltd), Aditya Birla Group (Hindalco & EMIL).
                                                </p>
                                                <p>As a Honorary Director Mr. Dubey serve as mentors, provide strategic guidance, or lend their expertise to special projects or initiatives.</p>
                                            </div>
                                            <h4 class="mt-4">Mr. Krishna Kant Dubey</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="container-fluid nybaex_container_fluid_padding">
                            <div class="row">
                                <div class="col-lg-3 col-md-2 col-sm-12 nybaex_hear_from_our_team_quote_icon">
                                    <img src="images/quote.svg" width="200" class="nybaex_hear_from_our_team_quote_image" />
                                </div>
                                <div class="col-lg-9 col-md-10 col-sm-12 nybaex_team_col_div">
                                    <h2>Hear from our team</h2>
                                    <div>
                                        <div class="nybaex_hear_from_our_team_home_text">
                                            <h4 class="mt-3 mb-3">Senior Developer</h4>
                                            <div class="pb-3 nybaex_team_content_div">
                                                <p>Mr. Nandan Kumar is a highly skilled & experienced Senior Developer at Nybaex, a leading IT/tech company that specializes in providing cutting-edge web solutions to its clients. With a proven track record of success and has been instrumental in the successful delivery of numerous projects. 
                                                </p>
                                                <p>At Nybaex, Mr. Nandan works closely with clients to understand their requirements & develop custom web solutions that meet their specific needs. He is known for his excellent problem-solving skills.</p>
                                            </div>
                                            <h4 class="mt-4">Mr. Nandan Kumar</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="container-fluid nybaex_container_fluid_padding">
                            <div class="row">
                                <div class="col-lg-3 col-md-2 col-sm-12 nybaex_hear_from_our_team_quote_icon">
                                    <img src="images/quote.svg" width="200" class="nybaex_hear_from_our_team_quote_image" />
                                </div>
                                <div class="col-lg-9 col-md-10 col-sm-12 nybaex_team_col_div">
                                    <h2>Hear from our team</h2>
                                    <div>
                                        <div class="nybaex_hear_from_our_team_home_text">
                                            <h4 class="mt-3 mb-3">Relationship Manager</h4>
                                            <div class="pb-3 nybaex_team_content_div">
                                                <p>Mr. Amit Kumar is the Relationship Manager at Nybaex Private Limited. With more than 8 years of experience, Mr. Amit is an expert in managing client relationships and delivering exceptional customer service. He plays a key role in identifying client needs, proposing tailored solutions, and ensuring successful project delivery.
                                                </p>
                                                <p>Amit's leadership, communication skills, and commitment to innovation helps to success. At Nybaex, Amit plays a pivotal role in fostering strong relationships with clients and ensuring their satisfaction with the company's services.</p>
                                            </div>
                                            <h4 class="mt-4">Mr. Amit Kumar</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="container-fluid nybaex_container_fluid_padding">
                            <div class="row">
                                <div class="col-lg-3 col-md-2 col-sm-12 nybaex_hear_from_our_team_quote_icon">
                                    <img src="images/quote.svg" width="200" class="nybaex_hear_from_our_team_quote_image" />
                                </div>
                                <div class="col-lg-9 col-md-10 col-sm-12 nybaex_team_col_div">
                                    <h2>Hear from our team</h2>
                                    <div>
                                        <div class="nybaex_hear_from_our_team_home_text">
                                            <h4 class="mt-3 mb-3">Project Manager</h4>
                                            <div class="pb-3 nybaex_team_content_div">
                                                <p>As the Project Manager of IT at Nybaex Private Limited, Mr. Pramod Singh is responsible for overseeing the development and implementation of technology projects, managing project budgets and timelines, and ensuring that projects are delivered on time and within budget. 
                                                </p>
                                                <p>With his extensive knowledge and expertise in project management and information technology, Mr. Pramod is committed to driving innovation and delivering exceptional results for Nybaex's clients.</p>
                                            </div>
                                            <h4 class="mt-4">Mr. Pramod Singh</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="container-fluid nybaex_container_fluid_padding">
                            <div class="row">
                                <div class="col-lg-3 col-md-2 col-sm-12 nybaex_hear_from_our_team_quote_icon">
                                    <img src="images/quote.svg" width="200" class="nybaex_hear_from_our_team_quote_image" />
                                </div>
                                <div class="col-lg-9 col-md-10 col-sm-12 nybaex_team_col_div">
                                    <h2>Hear from our team</h2>
                                    <div>
                                        <div class="nybaex_hear_from_our_team_home_text">
                                            <h4 class="mt-3 mb-3">Head Of SPN Department</h4>
                                            <div class="pb-3 nybaex_team_content_div">
                                                <p>Mr. Amit Mahato is an accomplished professional, serving as the Head of SPN Department at Nybaex Private Limited. With a wealth of experience in the realm of Service Provider Networks (SPN), he has played a pivotal role in expanding the company's reach and services.
                                                </p>
                                                <p>Mr. Amit's expertise in SPN management has been instrumental in optimizing the network infrastructure, ensuring seamless and efficient operations for Nybaex's clientele. His visionary approach and deep understanding of the dynamic e-commerce landscape have earned him a reputation as a trusted leader in the industry.</p>
                                            </div>
                                            <h4 class="mt-4">Mr. Amit Mahato</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="py-4">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 nybaex_quote_section_about">
                        <h1 class="heading text-white pt-2 pb-4 nybaex_quote_heading_about">Revolutionizing Business with Mobile Apps <i class="bi bi-phone"></i></h1>
                        <p class="mb-3">Our company offers cutting-edge Mobile app development solutions that help businesses streamline their operations and reach their customers in new ways. Our team of developers can create custom apps that are tailored to the specific needs of your business. Our Mobile apps are designed to be user-friendly, efficient, and secure, ensuring that your employees and customers can access the information they need quickly and easily.
                        <br /><br />
                        Whether you're looking to create a customer-facing app to improve engagement and sales, or an internal app to enhance productivity and communication, our Mobile app development services can help take your business to the next level.</p>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 nybaex_quote_section_about_col">
                        <img class="nybaex_about_nybaex_about_image" src="./images/home/8.png" width="600" />
                    </div>
                </div> 
            </div> 
        </section>


        <section class="nybaex_mention_details_about my-5 py-2">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 nybaex_mention_details_about_col">
                        <img class="nybaex_basic_details_about_image" src="./images/about/2.png" alt="" loading="lazy" />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 nybaex_mention_details_about_col">
                        <div class="pg-content">
                            <h2 class="pg-heading mb-4">We believe in the power of technology</h2>
                            <p>Our mission is to provide our clients with the tools, insights, and support they need to succeed in today's rapidly evolving digital landscape. Our team is made up of experts in a variety of fields, including software development, web development, app development, and more.
                            <br /><br />
                            If you are looking for a reliable and trusted IT solutions provider, look no further than Nybaex Private Limited. Contact us today to learn more about our services and how we can help you succeed.
                            </p>
                            <div class="pg-button-container mt-5">
                                <Link to="/contact" class="btn primary-btn px-5 nybaex_button_bg">Contact Us</Link>
                                <Link to="/services" class="btn primary-btn px-5 mt-3 nybaex_button_outline">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default About;
import { Link } from "react-router-dom";

function Footer(){
    return <>
    <section class="footer_project_discuss_section">
        <div class="container nybaex_container_fluid_padding footer_project_discuss_section_inner_div">
            <div class="row footer_project_discuss_section_row">
                <div class="col-lg-8 col-md-8 col-sm-8 footer_project_discuss_section_col_left">
                    <h3 class="footer_project_discuss_section_h3_text">Feel free to discuss about your project with us.</h3>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 footer_project_discuss_section_col_right">
                    <Link to="/contact" class="btn primary-btn px-5 footer_contact_us_btn">Contact Us</Link>
                </div>
            </div>
        </div>
    </section>

    <section class="footer_call_now_section">
        <div class="container-fluid nybaex_container_fluid_padding footer_call_now_section_inner_div">
            <h2 class="footer_call_now_section_h2 text-center"><i class="bi bi-headset footer_call_now_section_i"></i>&nbsp; Support No. <a href="tel:+91 8986717542" class="footer_call_now_section_a_text">+918986717542</a></h2>
        </div>
    </section>

    <footer class="footer nybaex_footer_sm_margin pb-3">
        <div class="container-fluid nybaex_container_fluid_padding">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div id="custom_html-4" class="widget_text footer-widget widget_custom_html">
                        <div class="textwidget custom-html-widget">
                            <div class="footer-widget">
                                <h3>Services</h3>
                                <ul class="footer-links">
                                <li><span class="local-link">Website Designing & Development</span></li>
                                <li><span class="local-link">Digital Marketing</span></li>
                                <li><span class="local-link">Android Development</span></li>
                                <li><span class="local-link">IOS Development</span></li>
                                <li><span class="local-link">Software Development</span></li>
                                <li><span class="local-link">Graphic Designing</span></li>
                                <li><span class="local-link">SPN Services</span></li>
                                <li><span class="local-link">Website Maintenance</span></li>
                                <li><span class="local-link">Consulting</span></li>
                                <li><span class="local-link">Cybersecurity</span></li>
                                <li><span class="local-link">Domain</span></li>
                                <li><span class="local-link">Web Security</span></li>
                                <li><span class="local-link">Networking</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div id="custom_html-6" class="widget_text footer-widget widget_custom_html">
                        <div class="textwidget custom-html-widget">
                            <div class="footer-widget">
                                <h3>Industries</h3>
                                <ul class="footer-links">
                                    <li><span class="local-link">Consumer Goods and Distribution</span></li>
                                    <li><span class="local-link">Media, and Information Services</span></li>
                                    <li><span class="local-link">Education</span></li>
                                    <li><span class="local-link">Healthcare</span></li>
                                    <li><span class="local-link">Manufacturing</span></li>
                                    <li><span class="local-link">Public Services</span></li>
                                    <li><span class="local-link">Retail</span></li>
                                    <li><span class="local-link">Travel and Logistics</span></li>
                                </ul>
                                <h3>Insights</h3>
                                <ul class="footer-links">
                                    <li><span class="local-link">Cloud</span></li>
                                    <li><span class="local-link">Blockchain</span></li>
                                    <li><span class="local-link">Health and Wellness</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div id="custom_html-6" class="widget_text footer-widget widget_custom_html">
                        <div class="textwidget custom-html-widget">
                            <div class="footer-widget">
                                <h3>More Information</h3>
                                <ul class="footer-links">
                                    <li><Link type="button" data-bs-toggle="offcanvas" data-bs-target="#companyprofile" aria-controls="offcanvasTop" class="local-link">Company Profile</Link></li>
                                    <li><Link to="/about" class="local-link">About Us</Link></li>
                                    <li><Link type="button" data-bs-toggle="offcanvas" data-bs-target="#termsconditions" aria-controls="offcanvasTop" class="local-link">Terms & Conditions</Link></li>
                                    <li><Link type="button" data-bs-toggle="offcanvas" data-bs-target="#privacypolicy" aria-controls="offcanvasTop" class="local-link">Privacy Policy</Link></li>
                                    <li><Link type="button" data-bs-toggle="offcanvas" data-bs-target="#disclaimer" aria-controls="offcanvasTop" class="local-link">Disclaimer</Link></li>
                                    <li><Link type="button" data-bs-toggle="offcanvas" data-bs-target="#termsofservice" aria-controls="offcanvasTop" class="local-link">Terms of Service</Link></li>
                                    <li><Link type="button" data-bs-toggle="offcanvas" data-bs-target="#paymentpolicy" aria-controls="offcanvasTop" class="local-link">Payment Policy</Link></li>
                                    <li><Link type="button" data-bs-toggle="offcanvas" data-bs-target="#fraudawareness" aria-controls="offcanvasTop" class="local-link">Fraud Awareness</Link></li>
                                    <li><Link type="button" data-bs-toggle="offcanvas" data-bs-target="#refundpolicy" aria-controls="offcanvasTop" class="local-link">Refund Policy</Link></li>
                                    <li><Link type="button" data-bs-toggle="offcanvas" data-bs-target="#cookiepolicy" aria-controls="offcanvasTop" class="local-link">Cookie Policy</Link></li>
                                    <li><Link type="button" data-bs-toggle="offcanvas" data-bs-target="#whynybaex" aria-controls="offcanvasTop" class="local-link">Why Nybaex</Link></li>
                                    <li><Link to="/career" class="local-link">Careers</Link></li>
                                    <li><Link to="/contact" class="local-link">Contact Us</Link></li>
                                    <li class="nybaex_sm_copyright_text mt-4"><a class="local-link">Copyright © 2023&nbsp; Nybaex Private Limited</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 nybaex_hide_sm">
                    <div id="custom_html-3" class="widget_text footer-widget widget_custom_html">
                        <div class="textwidget custom-html-widget">
                            <div class="footer-widget">
                                <h3>Knowledge Base</h3>
                                <ul class="footer-links">
                                    <li><span class="local-link">HTML</span></li>
                                    <li><span class="local-link">CSS</span></li>
                                    <li><span class="local-link">Javascript</span></li>
                                    <li><span class="local-link">JQuery</span></li>
                                    <li><span class="local-link">Bootstrap</span></li>
                                    <li><span class="local-link">React JS</span></li>
                                    <li><span class="local-link">React Native</span></li>
                                    <li><span class="local-link">Node JS</span></li>
                                    <li><span class="local-link">PHP</span></li>
                                    <li><span class="local-link">Python</span></li>
                                    <li><span class="local-link">Java</span></li>
                                    <li><span class="local-link">SQL</span></li>
                                    <li><span class="local-link">WordPress</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <section class="nybaex_footer_credit_area_section" style={{borderTop: "1px solid #1e2222"}}>
        <div class="container-fluid nybaex_container_fluid_padding py-3">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12 nybaex_lg_credit_footer">
                    <div class="copyright text-center text-md-start">
                        <img src="images/logo.png" width="190" alt="Nybaex Logo" />
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12 nybaex_bottom_footer_smlg">
                    <div class="copyright text-center text-md-start">
                        <p>Copyright © 2023&nbsp; Nybaex Private Limited</p>
                    </div>
                    <div>
                        <a href="https://api.whatsapp.com/send?phone=+918986717542&text=Hi" target="_blank" class="nybaex_bottom_footer_smlg_a"><i class="bi bi-whatsapp"></i></a>
                        <a href="https://facebook.com/nybaex" target="_blank" class="nybaex_bottom_footer_smlg_a"><i class="bi bi-facebook"></i></a>
                        <a href="https://youtube.com/@nybaex" target="_blank" class="nybaex_bottom_footer_smlg_a"><i class="bi bi-youtube"></i></a>
                        <a href="https://twitter.com/nybaex" target="_blank" class="nybaex_bottom_footer_smlg_a"><i class="bi bi-twitter"></i></a>
                        <a href="https://linkedin.com/nybaex" target="_blank" class="nybaex_bottom_footer_smlg_a_last"><i class="bi bi-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <div class="offcanvas offcanvas-top nybaex_footer_offcanvas" tabindex="-1" id="companyprofile" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-body p-0 pt-2 nybaex_dark_theme">
            <section>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active nybaex_slider_img">
                            <div class="nybaex_topsection_background_other">
                                <div class="col-12 p-4 m-0">
                                    <h4 class="m-0 nybaex_topsection_page_name_other">Explore &nbsp; <i class="bi bi-stars"></i></h4>
                                    <hr />
                                    <h2 class="nybaex_topsection_background_text_other">Company Profile</h2>
                                    <div class="footer_offcanvas_close_div">
                                        <span class="footer_offcanvas_close_button"><a type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="py-5">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <div class="row">
                        <div class="col-lg-5 col-md-12 col-sm-12 nybaex_quote_section_about_col nybaex_company_profile_logo_div">
                            <img class="nybaex_about_nybaex_about_image nybaex_company_profile_logo" src="./images/mylogo.png" width="400" />
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12 nybaex_quote_section_about mt-5">
                        <p class="mb-3 text-white" style={{textAlign: "justify"}}><b>Nybaex Private Limited</b> is a leading IT/Tech company registered with MCA having CIN: U62099JH2023PTC020267. With a mission to provide innovative and cutting-edge solutions to businesses across the globe, Nybaex Private Limited has established itself as a trusted partner in the tech industry.
                        <br /><br />
                        <b>Nybaex</b> is a technology company that provides IT solutions to clients across various industries. The company was founded with the goal of helping businesses harness the power of technology to streamline processes, improve efficiency, and drive growth.
                        <br /><br />
                        At Nybaex, we are dedicated to delivering innovative and reliable IT solutions that meet the unique needs of each of our clients. Our team of experts is highly skilled in a variety of technologies, including software development, website development, mobile app development, cybersecurity and so on.
                        <br /><br />
                        We understand that technology is constantly evolving, and we strive to stay ahead of the curve by continuously educating ourselves and incorporating the latest advancements into our services. Our focus is always on providing the highest level of customer satisfaction, and we take pride in our ability to build long-lasting partnerships with our clients.</p>
                        </div>
                        <div class="col-12 nybaex_quote_section_about mb-4">
                        <p class="mt-5 mb-3 text-white" style={{textAlign: "justify"}}>
                        Whether you're looking to improve your existing systems, or you're starting from scratch, Nybaex is here to help. We would be honored to have the opportunity to work with you and help you achieve your goals.
                        <br /><br />
                        Nybaex Private Limited is a company that operates in the information technology sector and is located in India. The IT industry in India is a rapidly growing sector, with a large pool of talented professionals and a growing demand for technology solutions, making it a favorable environment for IT companies like Nybaex to thrive.
                        <br /><br />
                        IT companies play an important role in the development of modern economies by providing businesses and organizations with the tools they need to stay competitive and achieve their goals. The growth of IT companies is often a good indicator of the growth of the technology sector in a region, and the increasing demand for technology services and solutions worldwide is driving the growth of the IT industry.
                        <br /><br />
                        Our team of skilled and experienced professionals are experts in their respective fields, including software development, web development, mobile application development, cloud computing, artificial intelligence, machine learning, and more. We are committed to delivering exceptional quality and customer satisfaction, and we strive to exceed our client's expectations with every project we undertake.
                        <br /><br />
                        At Nybaex Private Limited, we believe that content is key to building a strong online presence and engaging with customers. We understand that content creation can be a time-consuming and challenging task for businesses, which is why we offer a range of content services to help our clients establish their brand voice and reach their target audience.
                        <br /><br />
                        Whether you need help developing a content strategy, creating high-quality content, or promoting your content through various channels, <b>Nybaex Private Limited</b> has the expertise and experience to help you achieve your goals. Contact us today to learn more about how we can help your business grow.</p>
                        </div>
                    </div> 
                </div> 
            </section>


            <section class="nybaex_our_services_home nybaex_dark_theme mb-4">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <div class="pb-3">
                        <div class="card-body mx-auto">
                            <div class="accordion" id="accordion-services">

                                <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                    <h3 class="accordion-header" id="heading-11">
                                    <button class="accordion-button fs-xl shadow-none rounded-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-11" aria-expanded="true" aria-controls="collapse-11">Our Mission</button>
                                    </h3>
                                    <div class="accordion-collapse collapse show" id="collapse-11" aria-labelledby="heading-11" data-bs-parent="#accordion-services">
                                        <div class="accordion-body py-5">
                                            <div class="d-flex flex-md-row flex-column align-items-md-center">
                                                <div class="nybaex_tab_swipe_image_center_div">
                                                    <img src="./images/mission.png" width="200" alt="Digital Marketing" class="me-md-4 mb-md-0 mb-3" />
                                                </div>
                                                <div class="ps-md-3">
                                                    <p class="mb-0">At <b>Nybaex Private Limited</b>, our mission is to provide innovative technology solutions that empower businesses, organizations, and individuals to achieve their goals.
                                                    <br /><br />
                                                    At <b>Nybaex</b>, we believe in fostering a culture of collaboration, innovation, and excellence. We're committed to investing in our team members, providing them with the training, support, and resources they need to thrive and succeed.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                    <h3 class="accordion-header" id="heading-12">
                                    <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-12" aria-expanded="true" aria-controls="collapse-12">Our Vision</button>
                                    </h3>
                                    <div class="accordion-collapse collapse" id="collapse-12" aria-labelledby="heading-12" data-bs-parent="#accordion-services">
                                        <div class="accordion-body py-5">
                                            <div class="d-flex flex-md-row flex-column align-items-md-center">
                                                <div class="nybaex_tab_swipe_image_center_div">
                                                    <img src="./images/vision.png" width="200" alt="Web Development" class="me-md-4 mb-md-0 mb-3" />
                                                </div>
                                                <div class="ps-md-3">
                                                    <p class="mb-0">At <b>Nybaex</b>, we envision a future where technology is seamlessly integrated into our lives, making it simpler, smarter, and more efficient. We believe that technology can be a powerful force for good, and we're committed to harnessing its potential to make a positive impact on the world.
                                                    <br /><br />
                                                    At <b>Nybaex Private Limited</b>, we're passionate about creating technology solutions that make a difference. Join us as we continue to revolutionize the world with our innovative products and services.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                    <h3 class="accordion-header" id="heading-13">
                                    <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-13" aria-expanded="true" aria-controls="collapse-13">Core Values</button>
                                    </h3>
                                    <div class="accordion-collapse collapse" id="collapse-13" aria-labelledby="heading-13" data-bs-parent="#accordion-services">
                                        <div class="accordion-body py-5">
                                            <div class="d-flex flex-md-row flex-column align-items-md-center">
                                                <div class="nybaex_tab_swipe_image_center_div">
                                                    <img src="./images/values.png" width="200" alt="Core Values" class="me-md-4 mb-md-0 mb-3" />
                                                </div>
                                                <div class="ps-md-3">
                                                    <p class="mb-0">At <b>Nybaex Private Limited</b>, our core values are at the heart of everything we do. We believe that these values are critical to our success and are the foundation of our culture.
                                                    <br /><br />
                                                    <b>Innovation</b> : We are committed to being at the forefront of technological innovation, constantly pushing the boundaries of what's possible to create products and services that are efficient, effective, and user-friendly.
                                                    <br /><br />
                                                    <b>Excellence</b> : We strive for excellence in everything we do, from our interactions with clients to the quality of our work. We're dedicated to delivering exceptional results that exceed our client's expectations.
                                                    <br /><br />
                                                    <b>Customer Satisfaction</b> : We prioritize customer satisfaction above all else. We work closely with our clients to understand their needs and objectives, and we're committed to delivering solutions that meet and exceed their expectations.
                                                    <br /><br />
                                                    <b>Integrity</b> : We believe in operating with the utmost integrity and transparency in all of our business dealings. We hold ourselves to the highest ethical standards, and we always act with honesty and integrity.
                                                    <br /><br />
                                                    <b>Collaboration</b> : We believe in the power of collaboration and teamwork. We foster a culture of collaboration and communication, encouraging our team members to work together to achieve common goals.
                                                    <br /><br />
                                                    <b>Continuous Improvement</b> : We're dedicated to continuous improvement, both as individuals and as a company. We're always looking for ways to improve our processes, products, and services to better serve our clients and stay ahead of the curve.
                                                    <br /><br />
                                                    <b>Social Responsibility</b> : We believe that as a company, we have a responsibility to make a positive impact on the world. We're committed to using our expertise and resources to create solutions that help make the world a better place.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>


    <div class="offcanvas offcanvas-top nybaex_footer_offcanvas" tabindex="-1" id="termsconditions" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-body p-0 pt-2 nybaex_dark_theme">
            <section>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active nybaex_slider_img">
                            <div class="nybaex_topsection_background_other">
                                <div class="col-12 p-4 m-0">
                                    <h4 class="m-0 nybaex_topsection_page_name_other">Explore &nbsp; <i class="bi bi-stars"></i></h4>
                                    <hr />
                                    <h2 class="nybaex_topsection_background_text_other">Terms & Conditions</h2>
                                    <div class="footer_offcanvas_close_div">
                                        <span class="footer_offcanvas_close_button"><a type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-5">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <h3 class="mb-3 mt-0">Introduction</h3>
                    <p class="mb-3 text-white" style={{textAlign: "justify"}}>This document constitutes the Terms and Conditions (the "Agreement") between the customer (the "Client") and Nybaex Private Limited. This Agreement sets forth the terms and conditions under which the Service Provider agrees to provide IT services to the Client.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Services Provided</h3>
                    The Service Provider agrees to provide the Client with the IT services described in the Statement of Work (SOW) attached to this Agreement. The Service Provider reserves the right to modify the SOW in its sole discretion, provided that the Service Provider provides written notice to the Client of any material modifications to the SOW.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Payment Terms</h3>
                    The Client agrees to pay the Service Provider for the services provided in accordance with the payment schedule set forth in the SOW attached to this Agreement. Any amounts not paid when due will accrue interest at a rate of 10%.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Term and Termination</h3>
                    This Agreement shall commence on the effective date set forth in the SOW and shall continue until the completion of the services set forth in the SOW or until terminated by either party upon thirty (30) days written notice to the other party.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Confidentiality</h3>
                    The Service Provider agrees to maintain the confidentiality of the Client's confidential information and to use such confidential information only for the purpose of performing services under this Agreement.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Warranties</h3>
                    The Service Provider represents and warrants that it has the necessary expertise to perform the services set forth in the SOW and that it will perform such services in a professional and workmanlike manner. The Client represents and warrants that it has the right to enter into this Agreement and to use the services provided by the Service Provider.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Limitation of Liability</h3>
                    In no event shall either party be liable to the other party for any indirect, incidental, punitive, or consequential damages arising out of this Agreement or the performance or non-performance of services under this Agreement.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Dispute Resolution</h3>
                    Any disputes arising out of or related to this Agreement shall be resolved through arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in [insert location].
                    <br /><br />
                    <h3 class="mb-3 mt-0">Governing Law</h3>
                    This Agreement shall be governed by and construed in accordance with the laws of the State of Jharkhand, without giving effect to any choice of law rules.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Entire Agreement</h3>
                    This Agreement constitutes the entire agreement between the parties and supersedes all prior and contemporaneous agreements, representations, and understandings, whether written or oral, between the parties with respect to the subject matter of this Agreement.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Amendment</h3>
                    This Agreement may be amended only by written agreement executed by both parties.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Independent Contractor</h3>
                    The Service Provider is an independent contractor and shall have no authority to bind the Client in any way. The Service Provider shall not be deemed an employee of the Client for any purpose.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Assignment</h3>
                    The Client may not assign its rights or obligations under this Agreement without the prior written consent of the Service Provider. The Service Provider may assign its rights and obligations under this Agreement upon written notice to the Client.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Severability</h3>
                    If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Notice</h3>
                    Any notice required or permitted under this Agreement shall be in writing and shall be delivered by hand, mail, or email.</p>
                </div>
            </section>

        </div>
    </div>



    <div class="offcanvas offcanvas-top nybaex_footer_offcanvas" tabindex="-1" id="privacypolicy" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-body p-0 pt-2 nybaex_dark_theme">
            <section>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active nybaex_slider_img">
                            <div class="nybaex_topsection_background_other">
                                <div class="col-12 p-4 m-0">
                                    <h4 class="m-0 nybaex_topsection_page_name_other">Explore &nbsp; <i class="bi bi-stars"></i></h4>
                                    <hr />
                                    <h2 class="nybaex_topsection_background_text_other">Privacy Policy</h2>
                                    <div class="footer_offcanvas_close_div">
                                        <span class="footer_offcanvas_close_button"><a type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-5">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <h3 class="mb-3 mt-0">Introduction</h3>
                    <p class="mb-3 text-white" style={{textAlign: "justify"}}>At <b>Nybaex Private Limited</b>, we understand that privacy is important to our customers, clients, and users. This Privacy Policy outlines our commitment to protecting the personal information we collect from you through our website and other online and offline services.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Information Collection and Use</h3>
                    We collect a range of personal information from you, including your name, address, email address, and phone number. This information is used to provide you with our products and services, to process transactions, to send you promotional materials, and to improve our services.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Information Sharing</h3>
                    We do not sell or rent your personal information to third-party providers. However, we may share your information with our service providers, such as marketing partners, payment processors, and data storage providers, to the extent necessary to provide you with our products and services.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Data Security</h3>
                    We take the security of your personal information seriously and have implemented reasonable and appropriate physical, technical, and administrative measures to protect it from unauthorized access, use, disclosure, alteration, and destruction.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Your Rights</h3>
                    You have the right to access, correct, and delete your personal information, and to opt-out of marketing communications. If you would like to exercise these rights, please contact us at contact@nybaex.com.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Changes to This Policy</h3>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. If we make any material changes to this policy, we will notify you by posting a revised policy on our website.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Contact Information</h3>
                    If you have any questions or concerns about this Privacy Policy, please contact us at <b>contact@nybaex.com</b>.</p>
                </div>
            </section>

        </div>
    </div>


    <div class="offcanvas offcanvas-top nybaex_footer_offcanvas" tabindex="-1" id="disclaimer" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-body p-0 pt-2 nybaex_dark_theme">
            <section>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active nybaex_slider_img">
                            <div class="nybaex_topsection_background_other">
                                <div class="col-12 p-4 m-0">
                                    <h4 class="m-0 nybaex_topsection_page_name_other">Explore &nbsp; <i class="bi bi-stars"></i></h4>
                                    <hr />
                                    <h2 class="nybaex_topsection_background_text_other">Disclaimer</h2>
                                    <div class="footer_offcanvas_close_div">
                                        <span class="footer_offcanvas_close_button"><a type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-5">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <p class="mb-3 text-white" style={{textAlign: "justify"}}>The information contained in this website is for general information purposes only. The information is provided by <b>Nybaex Private Limited</b> and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained in the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
                    <br /><br />
                    In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
                    <br /><br />
                    Through this website you are able to link to other websites which are not under the control of Nybaex Private Limited. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
                    <br /><br />
                    Every effort is made to keep the website up and running smoothly. However, Nybaex Private Limited takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
                    <br /><br />
                    Nybaex Private Limited reserves the right to make changes to the website, its policies, and these disclaimer terms and conditions at any time.
                    <br /><br />
                    This disclaimer constitutes the entire agreement between you and Nybaex Private Limited relating to your use of this website and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the same.
                    <br /><br />
                    This disclaimer is governed by the laws. If any provision of this disclaimer is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced.
                    <br /><br />
                    Contact Us: If you have any questions or concerns regarding this disclaimer, please contact us at <b>contact@nybaex.com</b>.</p>
                </div>
            </section>

        </div>
    </div>



    <div class="offcanvas offcanvas-top nybaex_footer_offcanvas" tabindex="-1" id="termsofservice" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-body p-0 pt-2 nybaex_dark_theme">
            <section>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active nybaex_slider_img">
                            <div class="nybaex_topsection_background_other">
                                <div class="col-12 p-4 m-0">
                                    <h4 class="m-0 nybaex_topsection_page_name_other">Explore &nbsp; <i class="bi bi-stars"></i></h4>
                                    <hr />
                                    <h2 class="nybaex_topsection_background_text_other">Terms Of Service</h2>
                                    <div class="footer_offcanvas_close_div">
                                        <span class="footer_offcanvas_close_button"><a type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-5">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <p class="mb-3 text-white" style={{textAlign: "justify"}}>
                    <h3 class="mb-3 mt-0">Introduction</h3>
                    These terms of service (“Terms”) govern the use of the services provided by Nybaex Private Limited. By accessing or using our services, you agree to be bound by these Terms and all terms incorporated by reference. If you do not agree to these terms, do not use our services.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Services</h3>
                    Our services include, but are not limited to, website design and development, software development, app development, and other services provided by the Company (collectively, the “Services”). We reserve the right to modify, discontinue or terminate any Services at any time without notice.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Client Obligations</h3>
                    You agree to provide us with accurate and complete information necessary for us to provide the Services. You are responsible for the security of your passwords and account information, and you agree to notify us immediately of any unauthorized use of your account.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Payment Terms</h3>
                    You agree to pay for the Services in accordance with the fee schedule set forth on the Company’s website or as otherwise agreed upon between you and the Company. All fees are non-refundable and payable in advance. We reserve the right to change our fees at any time, with or without notice.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Intellectual Property</h3>
                    The Company retains all rights, title, and interest in and to its technology, including without limitation, all software, databases, proprietary methods and processes, inventions, patents, trade secrets and know-how. You shall not reverse engineer, decompile or disassemble any software provided to you as part of the Services.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Confidentiality</h3>
                    The Company shall maintain the confidentiality of all confidential information disclosed by you. “Confidential Information” shall include all information or material that has or could have commercial value or other utility in the business if not made public. If necessary, the Company may disclose confidential information to its employees, contractors, and agents who have a need to know and are under obligations of confidentiality.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Representations and Warranties</h3>
                    You represent and warrant that: (a) you have the full right, power and authority to enter into these Terms and to perform the acts required of you hereunder; (b) you own or have the necessary licenses, rights, consents and permissions to use and to authorize the Company to use all patent, trademark, trade secret, copyright or other proprietary rights in and to any and all of your User Content in the manner contemplated by these Terms; and (c) the use of your User Content by the Company will not violate the rights of any third party.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Limitation of Liability</h3>
                    To the maximum extent permitted by law, the Company shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, good-will, or other intangible losses, resulting from (a) your access to or use of or inability to access or use the Services; (b) any conduct or content of any third party on the Services; (c) any content obtained from the Services; and (d) unauthorized access, use or alteration of your transmissions or content.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Termination</h3>
                    Either party may terminate these Terms at any time with written notice. Upon termination, you will immediately stop using the Services and the Company may delete all of your User Content.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Dispute Resolution</h3>
                    Any dispute arising from these Terms shall be resolved through binding arbitration in accordance with the commercial</p>
                </div>
            </section>

        </div>
    </div>



    <div class="offcanvas offcanvas-top nybaex_footer_offcanvas" tabindex="-1" id="paymentpolicy" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-body p-0 pt-2 nybaex_dark_theme">
            <section>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active nybaex_slider_img">
                            <div class="nybaex_topsection_background_other">
                                <div class="col-12 p-4 m-0">
                                    <h4 class="m-0 nybaex_topsection_page_name_other">Explore &nbsp; <i class="bi bi-stars"></i></h4>
                                    <hr />
                                    <h2 class="nybaex_topsection_background_text_other">Payment Policy</h2>
                                    <div class="footer_offcanvas_close_div">
                                        <span class="footer_offcanvas_close_button"><a type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-5">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <h3 class="mb-3 mt-0">Introduction</h3>
                    <p class="mb-3 text-white" style={{textAlign: "justify"}}>This Payment Policy outlines the terms and conditions for payment of services provided by <b>Nybaex Private Limited</b>.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Payment Terms</h3>
                    Payment for services rendered is due 10 days from the date of invoicing. Any payment not received within 10 days from the due date will be subject to a late fee of ₹5000/-.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Accepted Forms of Payment</h3>
                    We accept payment by [list accepted forms of payment, e.g. check, UPI, Bank Transfer, RTEGS, credit card, PayPal].
                    <br /><br />
                    <h3 class="mb-3 mt-0">Invoicing</h3>
                    Invoices will include a detailed description of services rendered, the total amount due, and the due date.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Refunds</h3>
                    No refunds will be given for services rendered. In the event that services are not completed to the satisfaction of the client, Nybaex Private Limited will work with the client to resolve any issues and ensure their satisfaction.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Dispute Resolution</h3>
                    In the event of a dispute regarding payment, Nybaex Private Limited and the client will work together to resolve the issue in a timely and amicable manner. If a resolution cannot be reached, the matter may be referred to arbitration.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Changes to Payment Policy</h3>
                    Nybaex Private Limited reserves the right to change its Payment Policy at any time, with or without notice. Any changes to the policy will be posted on <b>www.nybaex.com</b>.
                    <br /><br />
                    By using the services of Nybaex Private Limited, the client agrees to be bound by the terms of this Payment Policy. If you have any questions or concerns regarding payment, please contact us at <b>contact@nybaex.com</b>.</p>
                </div>
            </section>

        </div>
    </div>



    <div class="offcanvas offcanvas-top nybaex_footer_offcanvas" tabindex="-1" id="fraudawareness" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-body p-0 pt-2 nybaex_dark_theme">
            <section>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active nybaex_slider_img">
                            <div class="nybaex_topsection_background_other">
                                <div class="col-12 p-4 m-0">
                                    <h4 class="m-0 nybaex_topsection_page_name_other">Explore &nbsp; <i class="bi bi-stars"></i></h4>
                                    <hr />
                                    <h2 class="nybaex_topsection_background_text_other">Fraud Awareness</h2>
                                    <div class="footer_offcanvas_close_div">
                                        <span class="footer_offcanvas_close_button"><a type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-5">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <h3 class="mb-3 mt-0">Introduction</h3>
                    <p class="mb-3 text-white" style={{textAlign: "justify"}}>As technology continues to evolve, so does the threat of fraud. It's important for individuals and companies to be aware of common scams and fraud schemes to protect themselves. Here are some tips to help you stay safe:
                    <br /><br />
                    <h3 class="mb-3 mt-0">Be cautious of unsolicited emails, phone calls, or text messages</h3>
                    If you receive an unexpected message from someone claiming to be a representative of a company, do not click on any links or provide any personal information until you can verify the sender's identity.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Verify the source</h3>
                    Before providing any personal information, make sure that the person or website requesting it is legitimate. Look for signs such as a secure website (https://), a professional-looking logo, and contact information for the company.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Use strong and unique passwords</h3>
                    Strong passwords that include a mix of letters, numbers, and symbols can help protect your accounts from being hacked. Avoid using easily guessable information, such as your name or birthdate, in your password.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Be careful with public Wi-Fi</h3>
                    Public Wi-Fi networks can be vulnerable to hacking, so avoid accessing sensitive information, such as online banking, on public Wi-Fi.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Keep software up-to-date</h3>
                    Regular software updates can help protect your devices from security vulnerabilities. Make sure to update your operating system, web browser, and any other software you use regularly.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Keep an eye on your financial statements</h3>
                    Regularly checking your bank and credit card statements can help you detect fraudulent activity early on. Report any suspicious transactions to your bank or credit card company immediately.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Use security tools</h3>
                    Anti-virus software, firewalls, and other security tools can help protect your devices and information from cyberattacks.
                    <br /><br />
                    Remember, staying vigilant and informed is key to protecting yourself and your information from fraud.</p>
                </div>
            </section>

        </div>
    </div>



    <div class="offcanvas offcanvas-top nybaex_footer_offcanvas" tabindex="-1" id="refundpolicy" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-body p-0 pt-2 nybaex_dark_theme">
            <section>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active nybaex_slider_img">
                            <div class="nybaex_topsection_background_other">
                                <div class="col-12 p-4 m-0">
                                    <h4 class="m-0 nybaex_topsection_page_name_other">Explore &nbsp; <i class="bi bi-stars"></i></h4>
                                    <hr />
                                    <h2 class="nybaex_topsection_background_text_other">Refund Policy</h2>
                                    <div class="footer_offcanvas_close_div">
                                        <span class="footer_offcanvas_close_button"><a type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-5">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <h3 class="mb-3 mt-0">Introduction</h3>
                    <p class="mb-3 text-white" style={{textAlign: "justify"}}>At Nybaex Private Limited, we strive to provide the highest quality of services to our customers. However, we understand that there may be circumstances where you may not be completely satisfied with our services. In such cases, we offer a refund policy to ensure that our customers are fully satisfied with their purchase.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Refund Eligibility</h3>
                    Refunds will only be provided in the following circumstances:
                    Our services did not meet the requirements and specifications agreed upon at the time of purchase.
                    Our services were not delivered as promised, were delayed, or were of poor quality.
                    If you have cancelled your service within the first 10 days of purchase and have not received the services for which you paid.
                    If there was an error in the billing process and you were overcharged for the services.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Refund Process</h3>
                    If you believe you are eligible for a refund, please follow the steps below:
                    <br /><br />
                    Contact our customer support team via email at <b>support@nybaex.com</b> to initiate the refund process.
                    Provide a detailed explanation of why you are seeking a refund, including any relevant documentation or evidence.
                    Our customer support team will review your request and determine if you are eligible for a refund.
                    If your refund request is approved, you will receive a full refund within 7 to 14 business days, depending on the payment method used.
                    <br /><br />
                    Note: Our refund policy does not apply to services that have been completed and delivered as promised.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Contact Us</h3>
                    If you have any questions or concerns regarding our refund policy, please contact us at <b>contact@nybaex.com</b> or +91 8986717524. We are always here to help and ensure that our customers are completely satisfied with our services.
                    <br /><br />
                    <b>This Refund Policy is subject to change at any time without prior notice</b>.</p>
                </div>
            </section>

        </div>
    </div>



    <div class="offcanvas offcanvas-top nybaex_footer_offcanvas" tabindex="-1" id="cookiepolicy" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-body p-0 pt-2 nybaex_dark_theme">
            <section>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active nybaex_slider_img">
                            <div class="nybaex_topsection_background_other">
                                <div class="col-12 p-4 m-0">
                                    <h4 class="m-0 nybaex_topsection_page_name_other">Explore &nbsp; <i class="bi bi-stars"></i></h4>
                                    <hr />
                                    <h2 class="nybaex_topsection_background_text_other">Cookie Policy</h2>
                                    <div class="footer_offcanvas_close_div">
                                        <span class="footer_offcanvas_close_button"><a type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-5">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <h3 class="mb-3 mt-0">Introduction</h3>
                    <p class="mb-3 text-white" style={{textAlign: "justify"}}>Our website uses cookies to enhance your user experience and to analyze site usage. This policy explains what cookies are and how we use them.
                    <br /><br />
                    <h3 class="mb-3 mt-0">What are Cookies?</h3>
                    Cookies are small text files that are stored on your computer, tablet or smartphone when you visit a website. These files contain information that can later be retrieved and used by the website.
                    <br /><br />
                    <h3 class="mb-3 mt-0">How We Use Cookies</h3>
                    We use cookies to improve your experience on our website, by remembering your preferences and making it easier for you to navigate. For example, cookies can be used to remember your login information.
                    <br /><br />
                    We also use cookies to analyze site usage, to understand how our visitors interact with our website. This information helps us to make improvements and to provide a better user experience.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Types of Cookies Used on Our Website</h3>
                    Necessary Cookies: These cookies are essential for the proper functioning of our website and cannot be disabled. They include, for example, cookies that allow you to log in to secure areas of our website.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Performance Cookies</h3>
                    These cookies collect information about how you use our website, for example, which pages you visit and if you encounter any errors. This information is used to improve the performance of our website.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Functionality Cookies</h3>
                    These cookies allow us to remember your preferences, such as your language preference or font size. This information is used to provide a more personalized user experience.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Advertising Cookies</h3>
                    These cookies are used to track your browsing habits and to show you relevant advertisements.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Managing Cookies</h3>
                    You can control and manage cookies through your browser settings. You can choose to accept all cookies, to be notified when a cookie is set, or to reject all cookies. Please note that if you choose to reject cookies, some features of our website may not work properly.
                    <br /><br />
                    For more information on managing cookies in your browser, please refer to your browser’s help resources.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Changes to Our Cookie Policy</h3>
                    We may update this policy from time to time to reflect changes in our practices or in response to new technologies. We will provide notice of such changes on this page.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Contact Us</h3>
                    If you have any questions about our use of cookies, please contact us at <b>contact@nybaex.com</b>.</p>
                </div>
            </section>

        </div>
    </div>



    <div class="offcanvas offcanvas-top nybaex_footer_offcanvas" tabindex="-1" id="whynybaex" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-body p-0 pt-2 nybaex_dark_theme">
            <section>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active nybaex_slider_img">
                            <div class="nybaex_topsection_background_other">
                                <div class="col-12 p-4 m-0">
                                    <h4 class="m-0 nybaex_topsection_page_name_other">Explore &nbsp; <i class="bi bi-stars"></i></h4>
                                    <hr />
                                    <h2 class="nybaex_topsection_background_text_other">Why Nybaex</h2>
                                    <div class="footer_offcanvas_close_div">
                                        <span class="footer_offcanvas_close_button"><a type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-5">
                <div class="container-fluid nybaex_container_fluid_padding">
                    <h3 class="mb-3 mt-0">Introduction</h3>
                    <p class="mb-3 text-white" style={{textAlign: "justify"}}><b>Nybaex Private Limited</b> is an IT company based in India that has established itself as a leading tech company in Jharkhand. There are several reasons why Nybaex is considered a top tech company in Jharkhand, including:
                    <br /><br />
                    <h3 class="mb-3 mt-0">Innovative Technology Solutions</h3>
                    <b>Nybaex</b> is known for providing innovative technology solutions that meet the diverse needs of its clients. The company uses the latest technology and cutting-edge techniques to deliver high-quality products and services.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Experienced Team</h3>
                    Nybaex has a team of highly skilled and experienced professionals who have a deep understanding of the industry and the technology they work with. The company's focus on hiring and developing top talent has helped it maintain its position as a leading tech company in Jharkhand.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Strong Client Base</h3>
                    Nybaex has built a strong client base, which includes leading businesses and organizations in India and around the world. The company's commitment to delivering high-quality services has helped it earn the trust of its clients, which has contributed to its success.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Focus on Customer Satisfaction</h3>
                    Nybaex places a strong emphasis on customer satisfaction. The company works closely with its clients to understand their needs and develops customized solutions that meet their unique requirements.
                    <br /><br />
                    <h3 class="mb-3 mt-0">Community Involvement</h3>
                    Nybaex is committed to giving back to the community and is involved in various initiatives to promote technology education and entrepreneurship in India. The company's dedication to making a positive impact on society has helped it gain recognition and respect in the industry.
                    <br /><br />
                    These are just some of the reasons why <b>Nybaex Private Limited</b> is considered a top tech company in Jharkhand. The company's continued focus on innovation, customer satisfaction, and community involvement will likely ensure its continued success in the future.</p>
                </div>
            </section>

        </div>
    </div>


    </>
  }
  
  export default Footer;
import { Link } from "react-router-dom";
import { useEffect } from "react";

function Services(){

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <section>
            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active nybaex_slider_img">
                        <div class="nybaex_topsection_background_other">
                            <div class="col-12 p-4 m-0">
                                <h4 class="m-0 nybaex_topsection_page_name_other">Our Services &nbsp; <i class="bi bi-code-slash"></i></h4>
                                <hr />
                                <h2 class="nybaex_topsection_background_text_other">Transforming businesses through technology</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="nybaex_app_details_home nybaex_dark_theme">
            <div class="container-fluid nybaex_app_details_home_container nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 nybaex_app_details_home_col">
                        <div class="pg-content">
                            <h2 class="pg-heading mb-5">Some common services offered by Nybaex !</h2>
                            <p class="mb-3">Welcome to <b>Nybaex Private Limited</b>, your one-stop solution for all your technology needs. We are a leading IT company providing a wide range of services to help businesses stay ahead in today's competitive market. Our team of experts has extensive experience in the industry and is dedicated to delivering the best possible solutions to meet your unique needs.
                            <br /><br />
                            At <b>Nybaex Private Limited</b>, we understand that technology is the backbone of modern businesses. That's why we're committed to providing you with the best possible solutions to help you stay ahead of the competition.</p>
                            <div class="pg-button-container mt-5 mb-5">
                                <Link to="/contact" class="btn primary-btn px-5 mb-2 nybaex_button_bg">Contact Us</Link>
                                <Link to="/about" class="btn primary-btn px-5 mb-2 nybaex_button_outline">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 nybaex_services_details_services_col">
                        <img class="nybaex_services_details_services_col_img" src="./images/home/6.png" alt="" />
                    </div>
                </div>
            </div>
        </section>


        <section class="nybaex_our_services_home nybaex_dark_theme mb-5">
            <div class="container-fluid nybaex_container_fluid_padding">
                <div class="pb-3">
                    <div class="card-body mx-auto">
                        <h2 class="mb-4 text-center">Our Competencies</h2>
                        <p class="mb-4 pb-5 fs-lg text-center">We fully understand your business. If you need to update or create something, we are more than happy to help you with the services we are providing.</p>

                        <div class="accordion" id="accordion-services">

                            <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 class="accordion-header" id="heading-1">
                                <button class="accordion-button fs-xl shadow-none rounded-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">Website Designing & Development</button>
                                </h3>
                                <div class="accordion-collapse collapse show" id="collapse-1" aria-labelledby="heading-1" data-bs-parent="#accordion-services">
                                <div class="accordion-body py-5">
                                    <div class="d-flex flex-md-row flex-column align-items-md-center">
                                        <div class="nybaex_tab_swipe_image_center_div">
                                            <img src="./images/icons/1.png" width="200" alt="Web Designing & Development" class="me-md-4 mb-md-0 mb-3" />
                                        </div>
                                    <div class="ps-md-3">
                                        <p class="mb-0">Website designing and development are the processes of creating and building a website from scratch. It involves several aspects such as website layout, user interface design, content creation, coding, and testing. The goal of website designing and development is to create a visually appealing, user-friendly, and functional website that can effectively communicate the message of the business or individual it represents. <br /><br />It is essential to have a well-designed and developed website to create a strong online presence, reach a wider audience, and achieve business goals. Professional website designers and developers use various tools and techniques to create a website that is optimized for search engines, responsive across all devices, and meets the needs and preferences of its users. Overall, website designing and development is a crucial aspect of building a successful online presence and achieving digital success.</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 class="accordion-header" id="heading-2">
                                <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="true" aria-controls="collapse-2">Android & IOS App Development</button>
                                </h3>
                                <div class="accordion-collapse collapse" id="collapse-2" aria-labelledby="heading-2" data-bs-parent="#accordion-services">
                                    <div class="accordion-body py-5">
                                        <div class="d-flex flex-md-row flex-column align-items-md-center">
                                            <div class="nybaex_tab_swipe_image_center_div">
                                                <img src="./images/icons/2.png" width="200" alt="Android Development" class="me-md-4 mb-md-0 mb-3" />
                                            </div>
                                            <div class="ps-md-3">
                                                <p class="mb-0">Android and iOS app development are the processes of creating mobile applications for the two most popular mobile operating systems, Android and iOS. Android and iOS app development involves several steps, such as ideation, design, development, testing, and deployment. These applications can be used for various purposes, such as entertainment, productivity, e-commerce, and more. Android and iOS app development requires knowledge of programming languages like Java, Kotlin, Swift, and Objective-C, as well as familiarity with app development frameworks and software development kits. <br /><br />Developers also need to consider various aspects like user interface design, app performance, security, and compatibility across multiple devices and operating systems. Android and iOS app development are crucial to building a successful mobile presence, and businesses and individuals alike can benefit from having a well-designed and developed mobile app. Overall, Android and iOS app development is an exciting and challenging field that requires creativity, technical skills, and a passion for creating innovative solutions that can improve people's lives.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 class="accordion-header" id="heading-3">
                                <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="true" aria-controls="collapse-3">Digital Marketing & Social Media Marketing</button>
                                </h3>
                                <div class="accordion-collapse collapse" id="collapse-3" aria-labelledby="heading-3" data-bs-parent="#accordion-services">
                                    <div class="accordion-body py-5">
                                        <div class="d-flex flex-md-row flex-column align-items-md-center">
                                            <div class="nybaex_tab_swipe_image_center_div">
                                                <img src="./images/icons/3.png" width="200" alt="Digital Marketing" class="me-md-4 mb-md-0 mb-3" />
                                            </div>
                                            <div class="ps-md-3">
                                                <p class="mb-0">Digital marketing and social media marketing are two important aspects of online marketing that businesses use to reach a wider audience and promote their products or services. Digital marketing encompasses all forms of online marketing, such as email marketing, search engine optimization (SEO), pay-per-click (PPC) advertising, content marketing, and more. Social media marketing, on the other hand, focuses specifically on promoting a business or brand through social media platforms like Facebook, Instagram, Twitter, and LinkedIn. <br /><br />It involves creating and sharing content that engages the audience and encourages them to interact with the business or brand. Social media marketing also involves monitoring and analyzing social media metrics to evaluate the success of campaigns and make data-driven decisions to improve the marketing strategy. Both digital marketing and social media marketing are crucial to building a successful online presence and reaching a wider audience. They require a deep understanding of audience demographics, consumer behavior, and the latest marketing trends and technologies. Overall, digital marketing and social media marketing are powerful tools that businesses can leverage to increase brand awareness, drive website traffic, and generate more leads and sales.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 class="accordion-header" id="heading-4">
                                <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="true" aria-controls="collapse-4">Graphic Designing & Animations</button>
                                </h3>
                                <div class="accordion-collapse collapse" id="collapse-4" aria-labelledby="heading-4" data-bs-parent="#accordion-services">
                                    <div class="accordion-body py-5">
                                        <div class="d-flex flex-md-row flex-column align-items-md-center">
                                            <div class="nybaex_tab_swipe_image_center_div">
                                                <img src="./images/icons/5.png" width="200" alt="Graphic Designing" class="me-md-4 mb-md-0 mb-3" />
                                            </div>
                                            <div class="ps-md-3">
                                                <p class="mb-0">Graphic designing and animations are two important components of visual communication that are used to create engaging and compelling visuals for a variety of media. Graphic design is the process of creating visual content using typography, color, and imagery to communicate a message or idea. It is used to create logos, brochures, posters, social media graphics, and other forms of digital and print media. Animations, on the other hand, involve creating moving images using computer graphics or hand-drawn illustrations. <br /><br />They can be used for various purposes such as entertainment, education, advertising, and more. Animations can be 2D or 3D and can range from simple motion graphics to complex special effects. Both graphic designing and animations require a deep understanding of design principles, color theory, typography, and software tools such as Adobe Illustrator, Photoshop, and After Effects. They also require creativity, attention to detail, and an ability to understand the needs and preferences of the target audience. Overall, graphic designing and animations are powerful tools that can help businesses and individuals create effective visual communication and achieve their marketing and communication goals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 class="accordion-header" id="heading-5">
                                <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="true" aria-controls="collapse-5">Cyber Security Services & Management</button>
                                </h3>
                                <div class="accordion-collapse collapse" id="collapse-5" aria-labelledby="heading-5" data-bs-parent="#accordion-services">
                                    <div class="accordion-body py-5">
                                        <div class="d-flex flex-md-row flex-column align-items-md-center">
                                            <div class="nybaex_tab_swipe_image_center_div">
                                                <img src="./images/icons/6.png" width="200" alt="Cyber security" class="me-md-4 mb-md-0 mb-3" />
                                            </div>
                                            <div class="ps-md-3">
                                                <p class="mb-0">Cybersecurity services and management are essential to protect organizations digital assets and data from cyber threats and attacks. Cybersecurity services include a range of security measures, such as firewall protection, encryption, intrusion detection, and prevention systems, and other forms of security software. These services are designed to prevent unauthorized access to sensitive data and protect against malicious attacks that can cause significant financial and reputational damage. Cybersecurity management involves implementing policies and procedures to ensure that organizations digital assets are secure and protected against cyber threats. It includes managing security risks, conducting regular vulnerability assessments, monitoring and analyzing security logs, and educating employees on best cybersecurity practices. <br /><br />Cybersecurity management also involves ensuring compliance with industry regulations and standards, such as HIPAA and GDPR. Overall, cybersecurity services and management are critical to protecting organizations digital assets, customer data, and reputation. As cyber threats continue to evolve, organizations must remain vigilant and implement robust cybersecurity measures to safeguard their digital infrastructure.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 class="accordion-header" id="heading-6">
                                <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-6" aria-expanded="true" aria-controls="collapse-6">Software Development & Maintenance</button>
                                </h3>
                                <div class="accordion-collapse collapse" id="collapse-6" aria-labelledby="heading-6" data-bs-parent="#accordion-services">
                                    <div class="accordion-body py-5">
                                        <div class="d-flex flex-md-row flex-column align-items-md-center">
                                            <div class="nybaex_tab_swipe_image_center_div">
                                                <img src="./images/icons/12.png" width="200" alt="Software Development" class="me-md-4 mb-md-0 mb-3" />
                                            </div>
                                            <div class="ps-md-3">
                                                <p class="mb-0">Software development and maintenance are essential aspects of creating and managing software applications. Software development involves creating new software applications or modifying existing ones to meet specific business needs or user requirements. It involves several stages such as planning, designing, coding, testing, and deployment. Developers use a variety of programming languages and tools to create software applications that are optimized for performance, scalability, and security. Software maintenance, on the other hand, involves making updates and changes to software applications to ensure they remain functional, secure, and up-to-date. It includes fixing bugs, optimizing performance, adding new features, and improving the user interface. <br /><br />Software maintenance is critical to ensuring that software applications remain effective and useful over time. It helps businesses avoid costly downtime and maintain a competitive edge by providing users with the latest software features and functionality. Overall, software development and maintenance are essential to building and managing effective software applications that meet the needs of businesses and users alike. They require technical expertise, attention to detail, and a commitment to ongoing improvement to ensure software applications remain secure, functional, and effective.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 class="accordion-header" id="heading-7">
                                <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-7" aria-expanded="true" aria-controls="collapse-7">Content Writing and Marketing</button>
                                </h3>
                                <div class="accordion-collapse collapse" id="collapse-7" aria-labelledby="heading-7" data-bs-parent="#accordion-services">
                                    <div class="accordion-body py-5">
                                        <div class="d-flex flex-md-row flex-column align-items-md-center">
                                            <div class="nybaex_tab_swipe_image_center_div">
                                                <img src="./images/icons/13.png" width="200" alt="Content Writing" class="me-md-4 mb-md-0 mb-3" />
                                            </div>
                                            <div class="ps-md-3">
                                                <p class="mb-0">Content writing and marketing are essential aspects of online marketing that businesses use to attract, engage, and retain customers. Content writing involves creating compelling written content such as blog posts, articles, social media posts, and website copy that provide value to the target audience. Effective content writing requires a deep understanding of the target audience's needs and preferences, as well as knowledge of the latest SEO best practices. Content marketing involves using the created content to promote a business or brand and attract potential customers. It involves distributing the content across multiple channels, such as social media, email marketing, and guest blogging, to reach a wider audience. Content marketing is effective in building brand awareness, driving website traffic, and generating leads and sales. <br /><br />It also helps businesses establish themselves as thought leaders in their industry, thereby enhancing their credibility and authority. Effective content writing and marketing require creativity, strategic thinking, and an ability to measure and analyze performance metrics to optimize content marketing efforts. Overall, content writing and marketing are powerful tools that businesses can use to increase their online visibility, engage with customers, and achieve their marketing goals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 class="accordion-header" id="heading-8">
                                <button class="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-8" aria-expanded="true" aria-controls="collapse-8">Service Provider Network (SPN) Services</button>
                                </h3>
                                <div class="accordion-collapse collapse" id="collapse-8" aria-labelledby="heading-8" data-bs-parent="#accordion-services">
                                    <div class="accordion-body py-5">
                                        <div class="d-flex flex-md-row flex-column align-items-md-center">
                                            <div class="nybaex_tab_swipe_image_center_div">
                                                <img src="./images/icons/14.png" width="200" alt="SPN Services" class="me-md-4 mb-md-0 mb-3" />
                                            </div>
                                            <div class="ps-md-3">
                                                <p class="mb-0">Service Provider Network (SPN) services are designed to provide businesses with a secure, reliable, and scalable network infrastructure to support their digital operations. SPN services enable businesses to access a range of network services, such as internet connectivity, cloud services, and virtual private networks (VPNs), from a single service provider. This allows businesses to reduce complexity, improve operational efficiency, and enhance their network performance. SPN services also include network monitoring, management, and security to ensure that businesses' network infrastructure is protected against cyber threats and is performing optimally. <br /><br />SPN services are particularly beneficial for small and medium-sized businesses that may not have the resources to manage and maintain their own network infrastructure. By outsourcing network services to a trusted service provider, businesses can focus on their core operations and achieve greater agility and flexibility. Overall, SPN services are critical to businesses' digital operations, enabling them to stay connected, secure, and competitive in today's digital economy.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="nybaex_app_details_home nybaex_dark_theme mb-5 py-3">
            <div class="container-fluid nybaex_app_details_home_container nybaex_container_fluid_padding">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 nybaex_services_details_services_col" style={{justifyContent: 'flex-start'}}>
                        <img class="nybaex_services_details_clients_services_col_img" src="./images/home/11.png" alt="" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 nybaex_app_details_home_col">
                        <div class="pg-content">
                            <h2 class="pg-heading mb-5">Powering Business Growth : How Nybaex Private Limited is Driving Client Success?</h2>
                            <p class="mb-3">Nybaex Private Limited is a leading IT company that is dedicated to helping its clients achieve growth and success. Through innovative solutions and cutting-edge technologies, Nybaex Private Limited has been able to drive measurable results for businesses of all sizes and industries.
                            <br /><br />
                            With a focus on customer satisfaction and a commitment to excellence, Nybaex Private Limited is poised to continue its impressive track record of client growth and success in the years to come.</p>
                            <div class="pg-button-container mt-5 mb-5">
                                <Link to="/contact" class="btn primary-btn px-5 mb-2 nybaex_button_bg">Contact Us</Link>
                                <Link to="/about" class="btn primary-btn px-5 mb-2 nybaex_button_outline">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Services;